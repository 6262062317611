import React, { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { EventParticipationAnswer } from '../../../types/event';
import IconBadge from '../icons/IconBadge';
import { eventParticipantAnswerIcons } from '../../../utils/iconUtils';

type ParticipationBadgeProps = {
  type: EventParticipationAnswer;
  size?: 'sm' | 'lg';
} & Omit<ComponentProps<typeof IconBadge>, 'icon'>;

export default function ParticipationBadge({ type, className, iconClassName, size = 'lg' }: ParticipationBadgeProps) {
  const classNamesMap = {
    [EventParticipationAnswer.YES]: 'text-primary-900 bg-primary-300',
    [EventParticipationAnswer.MAYBE]: 'text-secondary-50 bg-primary-900',
    [EventParticipationAnswer.NO]: 'text-primary-900',
    [EventParticipationAnswer.PENDING]: 'text-primary-900 bg-secondary-200',
  };

  const sizeMapBadge = {
    'sm': 'w-5 h-5 rounded-[5px]',
    'lg': 'w-6 h-6 rounded-[6px]',
  }

  const sizeMapIcon = {
    'sm': 'w-4 h-4',
    'lg': 'w-4 h-4',
  }

  return (
    <IconBadge
      icon={eventParticipantAnswerIcons[type]}
      className={twMerge(sizeMapBadge[size], classNamesMap[type], className)}
      iconClassName={twMerge(sizeMapIcon[size], iconClassName)}
    />
  );
}
