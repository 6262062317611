import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import Button from '../../components/buttons/Button';
import IconBadge from '../../components/icons/IconBadge';
import { downloadConnectionsExcel } from '../../../utils/fileUtils';
import { RootState } from '../../../redux/reducers';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import ChangeSelectionModal from '../../components/modals/ChangeSelectionModal';
import { ConnectionIdentifier } from '../../../types/misc';
import SelectExportedFields from '../../components/selection-lists/SelectExportedFields';
import { mapFieldIdentifiersToString, mapIdentifiersToConnections } from '../../../utils/connectionUtils';

export default function ExportExcelPage(): JSX.Element {
  const { t } = useTranslation();
  const connections = useSelector((state: RootState) => state.connections);
  const business = useSelectedBusiness();

  const [selectedConnections, setSelectedConnections] = useState<ConnectionIdentifier[]>(
    connections.map(({ id, type, userId }) => ({ id, type, userId })),
  );

  const [selectedFields, setSelectedFields] = useState<(string | number)[]>([]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedConnections(connections.map(({ id, type, userId }) => ({ id, type, userId })));
  }, [connections]);

  if (!business) {
    return <></>;
  }

  const customFields = [
    ...business.inviteFields?.customFields ?? [],
  ];

  return (
    <div className="grid grid-cols-8">
      <div className="col-span-8 lg:col-span-5 flex flex-col gap-2 border-b border-primary-300 pb-6 mb-6">
        <div className="flex ml-1 items-center mb-2">
          <div className="flex flex-col gap-4 w-full">
            <h1 className="flex items-center gap-4 text-3xl font-serif">
              <IconBadge icon={FaDownload} />
              {t('page.dashboard.downloadExcel.title')}
            </h1>
            <div className="bg-secondary-200 rounded-2xl p-4 text-justify">
              <Trans i18nKey="page.dashboard.downloadExcel.subtitle">
                You are about to download <strong>personal information</strong>. At Vera Connect, we understand that
                this is sometimes needed for practical reasons. However, please be very careful with this information
                and prevent that this information ends up with people that shouldn&apos;t have access to it. Also,
                please delete all copies if people that included ask you to do so.
              </Trans>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-8 lg:col-span-5 flex flex-col gap-2">
        <h2 className="font-serif text-xl ml-2">{t('page.dashboard.downloadExcel.selectContacts')}</h2>
        <div className="flex justify-between mb-2 pl-2">
          <span className="flex">
            <p className="text-primary font-semibold">{selectedConnections.length}</p>/{connections.length}
            {t('page.payment.selected')}
          </span>
          <p
            data-testid="edit-selection"
            onClick={(): void => setModalOpen(true)}
            className="underline cursor-pointer">
            {t('page.payment.editSelection')}
          </p>
        </div>
        <ChangeSelectionModal selection={selectedConnections} setSelection={setSelectedConnections} open={modalOpen}
                              setOpen={setModalOpen}
                              type="none" />
        <h2 className="font-serif text-xl ml-2">{t('page.dashboard.downloadExcel.selectData')}</h2>
        <SelectExportedFields selectedFields={selectedFields} setSelectedFields={setSelectedFields} className="mb-2 px-2" />
        <DownloadExcelButton selectedConnections={selectedConnections} selectedColumns={mapFieldIdentifiersToString(customFields, selectedFields)} />
      </div>
    </div>
  );
}

type DownloadExcelButtonProps = {
 selectedConnections: ConnectionIdentifier[];
 selectedColumns: string[];
}

function DownloadExcelButton({ selectedConnections, selectedColumns }: DownloadExcelButtonProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.dashboard.downloadExcel' });
  const business = useSelectedBusiness();
  const connections = useSelector((state: RootState) => state.connections);

  return (
    <Button
      type="button"
      className="col-span-8 lg:col-span-3 flex items-center justify-center bg-primary-300 text-white rounded-full h-12 self-end"
      onClick={() => downloadConnectionsExcel(
        mapIdentifiersToConnections(connections, selectedConnections),
        business?.name ?? '',
        selectedColumns,
      )}
      disabled={selectedConnections.length === 0}
    >
      {t('button')}
    </Button>
  );
}
