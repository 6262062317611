import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import ModalLayout from '../layouts/ModalLayout';
import { CustomPostcardTemplate, PostcardTemplate } from '../../../types/misc';
import CancelSaveButton from '../misc/CancelSaveButton';
import Button from '../buttons/Button';
import UploadPostcardModal, { UploadData } from './uploadPostcardModal';
import orderService from '../../../services/orderService';
import i18n from '../../../locales/i18n';

interface PostcardModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  templates: PostcardTemplate[];
  card: PostcardTemplate | null;
  setCard: (selectedCard: PostcardTemplate) => void;
  setTemplates: (templates: PostcardTemplate[]) => void;
  onUploadClose?: (data: UploadData) => void;
  onClose?: (isClose: boolean) => void;
}

export default function PostcardModal({
  showModal,
  setShowModal,
  setTemplates,
  templates,
  card,
  setCard,
  onUploadClose = (): void => {},
  onClose = (): void => {},
}: PostcardModalProps): JSX.Element {
  const [selectedCard, setSelectedCard] = useState<PostcardTemplate | null>(card);
  const [uploadPostcardModal, setUploadPostcardModal] = useState<boolean>(false);
  const [disabledUploadBtn, setDisabledUploadBtn] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const { t } = useTranslation();

  const handleCardSelect = (newCard: PostcardTemplate): void => {
    setSelectedCard(newCard);
    setIndex(templates.indexOf(newCard));

    const element = document.getElementsByClassName('active-card')[0];
    if (!element) return;
    if (templates.indexOf(newCard) < index)
      element.scrollIntoView({ behavior: 'smooth', inline: 'end' });
    else element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
  };

  useEffect(() => setSelectedCard(templates[0]), [templates]);

  useEffect((): any => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'ArrowLeft') {
        if (index === 0) return;
        handleCardSelect(templates[index - 1]);
      } else if (event.key === 'ArrowRight') {
        if (index === templates.length - 1) return;
        handleCardSelect(templates[index + 1]);
      }
    };

    const customTemplate = templates.filter(
      (template) => template.id === CustomPostcardTemplate.ID,
    );
    if (customTemplate.length === 5) {
      setDisabledUploadBtn(true);
    } else {
      setDisabledUploadBtn(false);
    }

    window.addEventListener('keydown', handleKeyDown);

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [index, templates]);

  const handleSubmit = (): void => {
    setCard(selectedCard!);
    onClose(true);
  };

  const handleDeletePostcard = async (data: PostcardTemplate): Promise<void> => {
    if (data.thumbnailId) {
      await orderService
        .deleteCustomPostcardImage(+data.thumbnailId)
        .then(() => {
          const updatedTemplates = templates.filter(
            (template) => template.thumbnailId !== data.thumbnailId,
          );
          setTemplates(updatedTemplates);
        })
        .catch(() => {
          toast.error(i18n.t('toast.error.deletePaidPostcard'));
        });
    } else {
      const updatedTemplates = templates.filter(
        (template) => template.thumbnail !== data.thumbnail,
      );
      setTemplates(updatedTemplates);
    }
  };

  return (
    <ModalLayout
      open={showModal}
      setOpen={setShowModal}
      className="bg-secondary-50 min-w-[400px] w-2/5 pb-4 pt-5  z-50 rounded-[20px] py-6">
      <div className="flex flex-col px-10 justify-center items-center">
        <div className="w-full">
          <p className="font-semibold text-lg ">{t('component.modal.postcard.title')}</p>
          <p>{t('component.modal.postcard.subtitle')}</p>
        </div>
        <img
          data-testid="card-big"
          src={templates.find((c) => c === selectedCard)?.thumbnail}
          alt="Card"
          className="min-w-[380px] min-h-[380px] max-w-96 max-h-96 border-primary-900 border-2 my-4"
        />
        <div className="relative flex w-full">
          {index > 0 && (
            <button
              data-testid="postcard-left"
              type="button"
              className="bg-[#d8f4f0] hover:bg-[#dff2f2] mx-auto absolute inset-y-1/2 -left-4 transform -translate-y-1/2 w-fit h-fit text-black p-2 rounded-lg z-10"
              onClick={(): void => handleCardSelect(templates[index - 1])}>
              <FaChevronLeft className="h-6 w-6" />
            </button>
          )}
          <div data-testid="card-scroller" className="flex w-full justify-start overflow-x-scroll">
            {templates.map(
              (image: PostcardTemplate, i: number): JSX.Element => (
                <>
                  <div className="relative min-w-fit">
                    <img
                      key={`${image.id}-${i}`}
                      data-testid={`${image.id}-${i}`}
                      src={image.thumbnail}
                      alt={image.name}
                      className={`h-24 w-24 border-primary-900 border-2 my-4 mx-2 cursor-pointer ${
                        selectedCard === image ? '!border-primary-300 active-card' : ''
                      }`}
                      onClick={(): void => setSelectedCard(image)}
                    />
                    {image.id === 'tmpl_I6wGz4fsoJBSmWMc1olD8' && (
                      <div>
                        <button
                          type="button"
                          className="absolute top-5 right-4 bg-red-500 text-white rounded-full p-1 text-xs"
                          onClick={async (): Promise<void> => handleDeletePostcard(image)}>
                          <MdClose className="w-4 h-4" />
                        </button>
                      </div>
                    )}
                  </div>
                </>
              ),
            )}
          </div>
          {index < templates.length - 1 && (
            <button
              data-testid="postcard-right"
              type="button"
              className="bg-[#d8f4f0] hover:bg-[#dff2f2] mx-auto absolute inset-y-1/2 -right-4 transform -translate-y-1/2 w-fit h-fit text-black p-2 rounded-lg z-10"
              onClick={(): void => handleCardSelect(templates[index + 1])}>
              <FaChevronRight className="h-6 w-6" />
            </button>
          )}
        </div>
        <div className="w-full mt-4 justify-end flex flex-row">
          <Button
            data-testid="upload-card"
            variant="primary"
            className="mr-auto"
            disabled={disabledUploadBtn}
            onClick={(): void => setUploadPostcardModal(true)}>
            {t('component.modal.postcard.button.upload')}
          </Button>
          <CancelSaveButton
            disabled={selectedCard === null}
            onSave={handleSubmit}
            onCancel={() => setShowModal(false)}
          />
        </div>
      </div>
      <UploadPostcardModal
        showModal={uploadPostcardModal}
        setShowModal={setUploadPostcardModal}
        onClose={onUploadClose}
      />
    </ModalLayout>
  );
}
