import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidFileExport } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import ModalLayout from '../layouts/ModalLayout';
import IconBadge from '../icons/IconBadge';
import CancelSaveButton from '../misc/CancelSaveButton';
import { Event, EventParticipationAnswer } from '../../../types/event';
import SelectExportedFields from '../selection-lists/SelectExportedFields';
import SelectAttendanceTypes from '../selection-lists/SelectAttendanceTypes';
import Slider from '../misc/Slider';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { downloadParticipationExcel } from '../../../utils/fileUtils';
import { mapFieldIdentifiersToString } from '../../../utils/connectionUtils';
import { RootState } from '../../../redux/reducers';
import { formatDateForFileName } from '../../../utils/stringUtils';

interface AttendanceListModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  event: Event;
}

export default function AttendanceListModal(
  {
    open,
    setOpen,
    event,
  }: AttendanceListModalProps): JSX.Element | null {
  const { t } = useTranslation();
  const [attendanceTypes, setAttendanceTypes] = React.useState<EventParticipationAnswer[]>([EventParticipationAnswer.YES]);
  const [showSelectFields, setShowSelectFields] = React.useState<boolean>(false);
  const [selectedFields, setSelectedFields] = React.useState<(string | number)[]>([]);
  const business = useSelectedBusiness();
  const connections = useSelector((state: RootState) => state.connections);

  useEffect(() => {
    setShowSelectFields(false);
    setAttendanceTypes([EventParticipationAnswer.YES]);
    setSelectedFields([]);
  }, [business]);

  const isFormValid = attendanceTypes.length > 0;

  const customFields = [
    ...business?.inviteFields?.customFields ?? [],
  ];

  const downloadAttendanceList = () => {
    downloadParticipationExcel(
      event.participants
        .filter(({ answer }) => answer ? attendanceTypes.includes(answer) : attendanceTypes.includes(EventParticipationAnswer.PENDING))
        .map((p) => [connections.find(({ userId }) => p.id === userId), p]),
      event.customFields,
      `${event.title}_${formatDateForFileName(event.startTime)}`,
      mapFieldIdentifiersToString(customFields, selectedFields),
    );
  };

  if (!open) return null;

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-secondary-50 min-w-[500px] flex flex-col pb-4 max-w-[600px] pt-5 mx-6 z-50 rounded-[20px] py-6 px-10 h-a gap-2">
      <div className="flex w-full">
        <IconBadge icon={BiSolidFileExport} />

        <div className="flex flex-col ml-4 mb-2 w-full">
          <h1 className="text-2xl font-serif">{t('component.modal.attendanceList.title')}</h1>
          <p>{t('component.modal.attendanceList.subtitle')}</p>
        </div>
      </div>

      <div className="flex flex-col gap-2 mb-4 w-full">
        <h3 className="font-semibold">{t('component.modal.attendanceList.attendanceType')}</h3>
        <SelectAttendanceTypes selectedTypes={attendanceTypes} setSelectedTypes={setAttendanceTypes} />
      </div>

      <div className="flex flex-col gap-2 w-full">
        <div className="flex items-center justify-between gap-1">
          <h3
            className="font-semibold">{showSelectFields ? t('component.modal.attendanceList.informationToExport') : t('component.modal.attendanceList.shouldExportInformation')}</h3>
          <Slider className="flex-shrink-0 cursor-pointer" state={showSelectFields}
                  handleToggle={() => setShowSelectFields(prev => !prev)} />
        </div>
        {showSelectFields &&
          <SelectExportedFields selectedFields={selectedFields} setSelectedFields={setSelectedFields} />
        }
      </div>

      <div className="w-full mt-6 justify-end flex">
        <CancelSaveButton
          buttonText={t('component.modal.attendanceList.download')}
          onCancel={() => setOpen(false)}
          disabled={!isFormValid}
          onSave={() => {
            downloadAttendanceList();
            setOpen(false);
          }}
        />
      </div>
    </ModalLayout>
  );
}
