import { useEffect } from 'react';

export default function useDelayedEffect(
  onStop: () => void,
  dependencies: any[],
  delay = 250,
): void {
  useEffect(() => {
    const delayDebounceFn = setTimeout(onStop, delay);

    return () => clearTimeout(delayDebounceFn);
  }, dependencies);
}
