import * as XLSX from 'xlsx';
import _ from 'lodash';
import i18n from 'i18next';
import { Connection } from '../redux/slices/connectionsSlice';
import { BusinessInvite, CustomFieldType } from '../services/model/inviteService.model';
import { ExcelColumn, ExcelColumnType } from '../types/misc';
import {
  excelColumnTypeToString,
  formatDate,
  formatDateAndTime,
  translateEventParticipationAnswer,
} from './stringUtils';
import { filterCustomLabelFields } from './filterUtils';
import { ProfileDataType } from '../types/Profile';
import { BasicInfo } from '../types/BasicInfo';
import {
  Event,
  EventCustomField,
  EventParticipant,
  EventParticipationAnswer,
} from '../types/event';

export const downloadExcel = (data: { [key: string]: string }[], fileName: string): void => {
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

  // Set the column widths
  const columnWidths: number[] = getColumnWidths(data);
  columnWidths.forEach((width: number, colIndex: number): void => {
    ws['!cols'] = ws['!cols'] || [];
    ws['!cols'][colIndex] = { width: width + 1 }; // Add some padding to the width
  });

  XLSX.utils.book_append_sheet(wb, ws, 'Connections');
  XLSX.writeFile(wb, fileName);
};

function getColumnWidths(record: Record<string, any>[]): number[] {
  const widths: number[] = [];
  record.forEach((row: Record<string, any>): void => {
    Object.keys(row).forEach((key: string, colIndex: number): void => {
      const value: any = row[key];
      const cellWidth: number = Math.max(String(key).length, String(value).length);
      widths[colIndex] = widths[colIndex] ? Math.max(widths[colIndex], cellWidth) : cellWidth;
    });
  });
  return widths;
}

export const downloadConnectionsExcel = (
  connections: Connection[],
  name?: string,
  labelsToDownload?: string[],
): void => {
  const data = connections.map((connection): { [key: string]: string } => {
    const { ADDRESS: address, ...restFields } = connection.fields;
    let usableFields = { ...restFields };
    let finalLabelsToDownload = labelsToDownload ? [...labelsToDownload] : undefined;

    if (address) {
      const [STREET, postCodeCity, COUNTRY] = address.split('\n');
      const [POSTAL_CODE, ...cityTokens] = postCodeCity.split(' ');
      usableFields = { ...usableFields, STREET, POSTAL_CODE, CITY: cityTokens.join(' '), COUNTRY };
      if (finalLabelsToDownload) {
        finalLabelsToDownload = [
          ...finalLabelsToDownload,
          'STREET',
          'POSTAL_CODE',
          'CITY',
          'COUNTRY',
        ];
      }
    }

    if (finalLabelsToDownload) {
      return _.pickBy(
        usableFields,
        (_value, key) =>
          key === BasicInfo.FIRST_NAME ||
          key === BasicInfo.LAST_NAME ||
          (finalLabelsToDownload && finalLabelsToDownload.includes(key)),
      );
    }

    return usableFields;
  });

  const newData = data.map((row: { [key: string]: string }): { [key: string]: string } => {
    const newRow: { [key: string]: string } = {};
    Object.keys(row).forEach((key: string): void => {
      newRow[excelColumnTypeToString(key as ExcelColumnType)] = row[key];
    });
    return newRow;
  });
  name = name ? name.replaceAll(' ', '_') : 'vera_moment';
  downloadExcel(newData, `${name}_contacten.xlsx`);
};

export const downloadParticipationExcel = (
  connections: [Connection | undefined, EventParticipant][],
  eventFields: EventCustomField[],
  name?: string,
  labelsToDownload?: string[],
): void => {
  const allowedKeys = [
    'EVENT_PARTICIPATION',
    'COMMNET',
    BasicInfo.FIRST_NAME,
    BasicInfo.LAST_NAME,
    ...eventFields.map((f) => f.label),
  ];

  const data = connections.map(([connection, participantData]): { [key: string]: string } => {
    if (connection) {
      const { ADDRESS: address, ...restFields } = connection.fields;
      let usableFields: { [p: string]: string } = {
        ...restFields,
        EVENT_PARTICIPATION: translateEventParticipationAnswer(
          participantData.answer ?? EventParticipationAnswer.PENDING,
        ),
        COMMENT: participantData.description,
        ...eventFields.reduce((prev, curr) => {
          prev[curr.label] =
            curr.responses.find((r) => r.userId === participantData.id)?.response ?? '';
          return prev;
        }, {} as any),
      };

      let finalLabelsToDownload = labelsToDownload ? [...labelsToDownload] : undefined;

      if (address) {
        const [STREET, postCodeCity, COUNTRY] = address.split('\n');
        const [POSTAL_CODE, ...cityTokens] = postCodeCity.split(' ');
        usableFields = {
          ...usableFields,
          STREET,
          POSTAL_CODE,
          CITY: cityTokens.join(' '),
          COUNTRY,
        };
        if (finalLabelsToDownload) {
          finalLabelsToDownload = [
            ...finalLabelsToDownload,
            'STREET',
            'POSTAL_CODE',
            'CITY',
            'COUNTRY',
          ];
        }
      }

      if (finalLabelsToDownload) {
        return _.pickBy(
          usableFields,
          (_value, key) =>
            allowedKeys.includes(key) ||
            (finalLabelsToDownload && finalLabelsToDownload.includes(key)),
        );
      }

      return usableFields;
    }

    return {
      FIRST_NAME: participantData.firstName,
      LAST_NAME: participantData.lastName,
      EVENT_PARTICIPATION: translateEventParticipationAnswer(
        participantData.answer ?? EventParticipationAnswer.PENDING,
      ),
      COMMENT: participantData.description,
      ...eventFields.reduce((prev, curr) => {
        prev[curr.label] =
          curr.responses.find((r) => r.userId === participantData.id)?.response ?? '';
        return prev;
      }, {} as any),
    };
  });

  const newData = data.map((row: { [key: string]: string }): { [key: string]: string } => {
    const newRow: { [key: string]: string } = {};
    const constantKeys = [
      ExcelColumnType.FIRST_NAME,
      ExcelColumnType.LAST_NAME,
      ExcelColumnType.EVENT_PARTICIPATION,
    ];
    const otherKeys = Object.keys(row).filter(
      (key) => !constantKeys.includes(key as ExcelColumnType),
    );

    [...constantKeys, ...otherKeys].forEach((key: string): void => {
      newRow[excelColumnTypeToString(key as ExcelColumnType)] = row[key];
    });

    return newRow;
  });

  name = name ? name.replaceAll(' ', '_') : 'event_list';
  downloadExcel(newData, `${name}.xlsx`);
};

export const downloadExcelExample = (invite: BusinessInvite): void => {
  const fields = [...invite.mandatoryFields, ...invite.optionalFields];

  const toString = excelColumnTypeToString;
  const headers = [toString(ExcelColumnType.FIRST_NAME), toString(ExcelColumnType.LAST_NAME)]; // Array for headers

  // Add data types and headers based on field types
  if (invite.customFields.find((f) => f.type === CustomFieldType.COMMUNICATION_NAME)) {
    headers.push(toString(ExcelColumnType.COMMUNICATION_NAME));
  }
  fields.forEach((field) => {
    if (field === ProfileDataType.EMAIL) {
      headers.push(toString(ExcelColumnType.EMAIL));
    } else if (field === ProfileDataType.PHONENUMBER) {
      headers.push(toString(ExcelColumnType.PHONENUMBER));
    } else if (field === ProfileDataType.ADDRESS) {
      headers.push(
        toString(ExcelColumnType.STREET),
        toString(ExcelColumnType.POSTAL_CODE),
        toString(ExcelColumnType.CITY),
        toString(ExcelColumnType.COUNTRY),
      );
    } else if (field === ProfileDataType.BIRTHDATE) {
      headers.push(toString(ExcelColumnType.BIRTHDATE));
    } else if (field === ProfileDataType.BUSINESSNAME) {
      headers.push(toString(ExcelColumnType.BUSINESSNAME));
    }
  });
  if (invite.customFields.length > 0) {
    headers.push(...filterCustomLabelFields(invite.customFields).map((field) => field.label));
  }
  const excelData = [headers];

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(excelData);

  const columnWidths: number[] = getColumnWidths(excelData);
  columnWidths.forEach((width: number, colIndex: number): void => {
    ws['!cols'] = ws['!cols'] || [];
    ws['!cols'][colIndex] = { width: width + 1 }; // Add some padding to the width
  });

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'output.xlsx');
};

export function parseColumns(file: File): Promise<ExcelColumn[]> {
  const processData = (data: ArrayBuffer): ExcelColumn[] => {
    const workbook = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd-mm-yy' });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const serializedData: { [key: string]: string }[] = XLSX.utils.sheet_to_json(worksheet, {
      raw: false,
      defval: '',
    });
    if (!serializedData.length) throw new Error('Geen data gevonden in het bestand');
    const columns: ExcelColumn[] = [];
    Object.keys(serializedData[0]).forEach((key) => {
      columns.push({ name: key, rows: serializedData.map((row) => row[key] || '') });
    });
    return columns;
  };

  return new Promise<ExcelColumn[]>((resolve, reject) => {
    if (!file) {
      reject(new Error('No file selected'));
      return;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = e.target!.result as ArrayBuffer;
        const columns = processData(data);
        resolve(columns);
      } catch (error) {
        console.error('An error occurred while processing the Excel file:', error);
        reject(error);
      }
    };

    reader.readAsBinaryString(file);
  });
}

/**
 * Downloads the analytics for the given events in an Excel file.
 *
 * @param events the events to download the analytics for
 * @param fileName name to save the file as; should be given without the .xlsx extension
 */
export function downloadAnalytics(events: Event[], fileName: string = 'report'): void {
  const eventData = getEventDataInExcelFormat(events);
  const userData = getUserDataInExcelFormat(events);

  const wb: XLSX.WorkBook = XLSX.utils.book_new();

  const eventWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(eventData);
  addPadding(eventData, eventWorksheet);

  const userWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(userData);
  addPadding(userData, userWorksheet);

  XLSX.utils.book_append_sheet(wb, userWorksheet, i18n.t('page.agenda.report.title'));
  XLSX.utils.book_append_sheet(wb, eventWorksheet, i18n.t('page.agenda.report.events'));
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

function getEventDataInExcelFormat(events: Event[]): Record<string, string>[] {
  return events.map((event) => ({
    [i18n.t('page.event.regular.name')]: event.title,
    [i18n.t('page.event.regular.startDate')]: formatDateAndTime(event.startTime),
    [i18n.t('page.event.regular.endDate')]: event.endTime ? formatDateAndTime(event.endTime) : '',
    [i18n.t('general.location')]: event.location.toString(),
    [i18n.t('page.event.regular.maximumAttendees')]: event.maximumAttendees?.toString() ?? '',
    [i18n.t('page.event.regular.deadline')]: event.deadline
      ? formatDateAndTime(event.deadline)
      : '',
    [i18n.t('general.description')]: event.description,
  }));
}

function getUserDataInExcelFormat(events: Event[]): Record<string, string>[] {
  const eventIdToNameMap = events.reduce((acc, event) => {
    return { ...acc, [event.id]: `${event.title} \r\n(${formatDate(event.startTime)})` };
  }, {} as Record<number, string>);

  const participants = _.chain(events)
    .flatMap((x) => x.participants)
    .uniqBy((x) => x.id)
    .value();

  // Transform the data in an object array where the keys are the full name of the user and the event names
  return (
    participants
      .map((participant) =>
        events.reduce(
          (acc, event) => {
            const participation = event.participants.find((p) => p.id === participant.id);
            return {
              ...acc,
              [event.id]: participation
                ? translateEventParticipationAnswer(
                    participation.answer || EventParticipationAnswer.PENDING,
                  )
                : 'x',
            };
          },
          { [i18n.t('general.fullName')]: `${participant.firstName} ${participant.lastName}` },
        ),
      )
      // Using ids instead of immediately transforming the id to name
      // accounts for the case where two events have the same name and happen on the same day
      .map((entry) =>
        _(entry)
          .transform((acc, value, key) => {
            const numericKey = Number.parseInt(key, 10);
            if (Number.isNaN(numericKey)) {
              acc[key] = value;
            } else {
              acc[eventIdToNameMap[numericKey]] = value;
            }
          }, {} as Record<string, string>)
          // make sure the full name is the first column
          .toPairs()
          .sort(([k1]) => (k1 === i18n.t('general.fullName') ? -1 : 1))
          .fromPairs()
          .value(),
      )
  );
}

function addPadding(
  data: Record<string, string>[],
  worksheet: XLSX.WorkSheet,
  widthToAdd: number = 1,
): void {
  const columnWidths: number[] = getColumnWidths(data);
  columnWidths.forEach((width: number, colIndex: number): void => {
    worksheet['!cols'] = worksheet['!cols'] || [];
    worksheet['!cols'][colIndex] = { width: width + widthToAdd }; // Add some padding to the width
  });
}
