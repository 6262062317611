import CountUp from 'react-countup';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type CountUpCardProps = {
  title: string;
  count: number;
  className?: string;
  duration?: number;
  variant?: 'primary' | 'secondary';
} & React.HTMLAttributes<HTMLDivElement>;

const VARIANT_MAP = {
  primary: 'bg-primary-300 text-primary-900',
  secondary: 'bg-secondary text-primary-900',
}

export default function CountUpCard({ title, count, duration = 3, variant = 'secondary', className, ...props }: CountUpCardProps): JSX.Element {
  return (
    <div className={twMerge('flex flex-col items-center justify-center p-4 rounded-md w-full max-md:py-16', VARIANT_MAP[variant], className)} {...props}>
      <CountUp preserveValue className="font-serif text-6xl" duration={duration} end={count} />
      <p className="font-semibold">{title}</p>
    </div>
  );
}
