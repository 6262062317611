import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FaArrowRight, FaArrowUpFromBracket, FaExclamation } from 'react-icons/fa6';
import React, { Fragment, useRef } from 'react';
import { MdOutlineUploadFile } from 'react-icons/md';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa';
import { ExcelColumn } from '../../../../types/misc';
import IconBadge from '../../icons/IconBadge';
import { downloadExcelExample, parseColumns } from '../../../../utils/fileUtils';
import { BusinessInvite } from '../../../../services/model/inviteService.model';
import useWindowDimensions from '../../../../hooks/effects/useWindowDimensions';
import { getSelectedBusiness } from '../../../../hooks/business/useSelectedBusiness';

interface ImportViewHeaderProps {
  invite: BusinessInvite;
}

export function ImportViewHeader({ invite }: ImportViewHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const [isOpened, setIsOpened] = React.useState<boolean>(false);

  const downloadExampleFile = () => {
    downloadExcelExample(invite);
    toast.success(t('toast.success.downloadSuccess'));
  };

  return (
    <>
      <div className="flex items-center mb-2">
        <IconBadge icon={FaArrowUpFromBracket} />

        <div className="flex flex-col ml-4 w-full">
          <h1 className="text-3xl font-serif">
            {t('component.modal.importLocalContacts.import.title')}
          </h1>
          <span>
            <Trans
              i18nKey="component.modal.importLocalContacts.import.subtitle"
              className="text-primary-900">
              Je kunt hier contacten importeren met Excel. Download
              <strong className="underline cursor-pointer" onClick={downloadExampleFile}>
                hier
              </strong>
            </Trans>
          </span>
        </div>
      </div>
      <div className="bg-secondary-200 rounded-2xl p-4">
        <p>{t('component.modal.importLocalContacts.import.excelWarning')}</p>
        <Transition
          as={Fragment}
          show={!isMobile || isOpened}
          enter="transform transition duration-200"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transform transition duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <ul className="list-disc list-inside">
            <li>
              <span className="font-bold">
                {t('component.modal.importLocalContacts.import.phones')}:
              </span>{' '}
              {t('component.modal.importLocalContacts.import.phoneTip')}
            </li>
            <li>
              <span className="font-bold">{t('dataType.datesOfBirth')}:</span>{' '}
              {t('component.modal.importLocalContacts.import.datesOfBirthTip')}
            </li>
          </ul>
        </Transition>
        {isMobile && (
          <button
            onClick={() => setIsOpened((prev) => !prev)}
            className="text-primary-900 underline font-semibold"
            type="button">
            {isOpened ? t('general.showLess') : t('general.showMore')}...
          </button>
        )}
      </div>
    </>
  );
}

interface ImportViewProps {
  setColumns: (columns: ExcelColumn[] | undefined) => void;
  setFileName: (name: string) => void;
}

export function ImportView({ setColumns, setFileName }: ImportViewProps): JSX.Element {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const business = getSelectedBusiness();

  const uploadExcelFile = async (file: File) => {
    setFileName(file.name);
    const columns = await parseColumns(file).catch(() => {
      toast.error('Er ging iets fout bij het importeren van je bestand');
      return [];
    });

    setColumns(columns);
  };

  const handleFileUpload = (files: FileList) => {
    if (files.length === 1) {
      const file = files[0];
      uploadExcelFile(file);
    } else {
      toast.error(t('toast.error.oneFile'));
    }
  };

  if (business && !business.inviteFields) {
    return (
      <div className="w-full mb-1 flex flex-col gap-4 border-2 h-fit p-4 items-center justify-center rounded-lg">
        <FaExclamationTriangle size={24} />
        <span className="font-medium text-primary-900 text-center items-center justify-center">
          {t('component.modal.importLocalContacts.import.missingRequestData')}
        </span>
        <Link
          to="/settings/requested-data"
          className="bg-primary-300 py-2 px-4 rounded-md text-primary-900 font-semibold hover:bg-opacity-75 flex items-center gap-1">
          {t('component.modal.importLocalContacts.import.toRequestedData')}
          <FaArrowRight size={14} />
        </Link>
      </div>
    );
  }

  return (
    <>
      <div
        onDrop={(e) => {
          e.preventDefault();
          handleFileUpload(e.dataTransfer.files);
        }}
        onClick={() => inputRef.current?.click()}
        className="w-full mb-1 flex-col border-2 cursor-pointer border-primary-900 border-dashed hover:border-opacity-80 hover:bg-primary-300 hover:bg-opacity-10 h-fit p-4 flex items-center justify-center rounded-lg">
        <MdOutlineUploadFile className="w-14 h-14 text-primary-900 mb-1" />
        <span className="font-medium text-sm text-primary-900 w-1/4 text-center items-center justify-center">
          <Trans i18nKey="component.modal.importLocalContacts.import.importButton" />
        </span>
      </div>
      <span className="text-xs font-medium text-primary-900">
        {t('component.modal.importLocalContacts.import.fileSupported')}
      </span>
      <input
        type="file"
        className="hidden"
        onChange={(e) => handleFileUpload(e.target.files!)}
        accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref={inputRef}
      />
    </>
  );
}
