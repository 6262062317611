import React, { useState } from 'react';
import _ from 'lodash';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { ProfileField } from '../../../types/Profile';
import adminService from '../../../services/adminService';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import CancelSaveButton from '../../components/misc/CancelSaveButton';
import useBusinessEffect from '../../../hooks/effects/useBusinessEffect';

export default function SharedDataPage() {
  const { t } = useBusinessTranslation();

  const [selectedFields, setSelectedFields] = useState<ProfileField[]>([]);
  const [sharedData, setSharedData] = useState<ProfileField[]>([]);

  useBusinessEffect(() => {
    adminService.getSharedData().then((data) => {
      setSelectedFields(data);
      setSharedData(data);
    });
  }, []);

  const handleSubmit = () => {
    adminService.updateSharedData(selectedFields.map((f) => +f.id!)).then(() => {
      setSharedData(selectedFields);
    });
  };

  return (
    <>
      <div className="flex flex-col border-b border-primary-300 pb-6 mb-6">
        <h1 className="font-semibold font-serif text-3xl mb-2">
          {t('page.settings.shared.title')}
        </h1>
        <p className="lg:w-1/2">{t('page.settings.shared.subtitle')}</p>
      </div>
      <div className="flex flex-col lg:w-2/3 gap-4 ">
        <UserDataForm
          selectedData={selectedFields}
          setSelectedData={setSelectedFields}
          hasSelectAll
          onlyBirthDate
        />
        <div className="flex justify-end w-full">
          <CancelSaveButton
            onCancel={() => {}}
            cancelClassName="hidden"
            onSave={handleSubmit}
            disabled={
              !_.difference(sharedData, selectedFields).length &&
              !_.difference(selectedFields, sharedData).length
            }
          />
        </div>
      </div>
    </>
  );
}
