import useWindowDimensions from './useWindowDimensions';

type ScreenSize = 'sm' | 'md' | 'lg' | 'xl';

/**
 * Hook to check if the screen size is smaller than the given size.
 *
 * @param{ScreenSize} size - the size to check against
 */
const useIsScreenSize = (size: ScreenSize) => {
  const { width } = useWindowDimensions();

  switch (size) {
    case 'sm':
      return width < 640;
    case 'md':
      return width < 768;
    case 'lg':
      return width < 1024;
    case 'xl':
      return width >= 1024;
    default:
      return false;
  }
};

export default useIsScreenSize;
