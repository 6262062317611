import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/misc/PageHeader';
import { SubscriptionType } from '../../../types/misc';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';

export default function SubscriptionsPage() {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader
        title={t('page.settings.subscriptions.title')}
        subtitle={t('page.settings.subscriptions.subtitle')}
        className="mb-6 pb-6 border-b border-primary-300"
      />
      <div className="w-full grid  grid-cols-1 md:grid-cols-3 gap-6 flex-1">
        {Object.values(SubscriptionType).map((s) => (
          <div
            className={`w-full h-full rounded-[20px] flex flex-col flex-1 px-6 py-4 items-center ${
              s === SubscriptionType.PRO ? 'bg-primary-900 white-text' : 'bg-secondary-200'
            }`}>
            <p className="font-semibold text-4xl font-serif">
              {capitalizeFirstLetter(s.toLowerCase())}
            </p>
            <div className="flex gap-2 items-center justify-center">
              <p className="font-semibold text-6xl font-serif">€{data[s].price}</p>
              <p className="font-semibold text-2xl"> / {t('general.month')}</p>
            </div>
            {data[s].yearly ? (
              <p className="font-medium text-lg">
                €{data[s].yearly} / {t('general.year')}
              </p>
            ) : (
              <div className="h-7" />
            )}
            <div className="w-full flex-col flex mt-10 gap-1">
              {data[s].features.map((f, i) => {
                const text = t(`page.settings.subscriptions.features.${s.toLowerCase()}.${f}`);
                const [comingSoon, paid] = [text.includes('coming soon'), text.includes('paid')];
                return (
                  <div className="flex  gap-2">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/landing/check.svg`}
                      className="w-5 h-5 mt-[3px]"
                      alt="check"
                    />
                    <p
                      className={`flex flex-wrap gap-2 ${
                        i === 0 && s !== SubscriptionType.FREE ? 'font-semibold' : ''
                      }`}>
                      {text.replaceAll(/\(coming soon\)|\(paid\)/g, '')}{' '}
                      {comingSoon && (
                        <span className="bg-secondary !text-primary-900 flex-shrink-0 h-fit w-fit rounded-[6px] flex items-center justify-center px-1 py-1 text-xs font-medium ">
                          {t(`page.settings.subscriptions.comingSoon`)}
                        </span>
                      )}
                      {paid && (
                        <span className="bg-secondary !text-primary-900 flex-shrink-0 h-fit w-fit rounded-[6px] flex items-center justify-center px-1 py-1 text-xs font-medium ">
                          {t(`page.settings.subscriptions.paid`)}
                        </span>
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
const data = {
  [SubscriptionType.FREE]: {
    price: 0,
    yearly: 0,
    features: [
      'upTo50Members',
      'oneAdmin',
      'memberOverview',
      'importExistingMemberList',
      'sendPushnotificationsAndEmails',
      'sendPhysicalCardsPaid',
      'planEvents',
      'datePicker',
    ],
  },
  [SubscriptionType.BASE]: {
    price: 19,
    yearly: 199,
    features: [
      'allFreePlanFeaturesPlus',
      'upTo200Members',
      'threeAdmins',
      'discountOnPhysicalCards',
      'sendPollsWithEvent',
      'addMaxParticipantsToEvents',
      'eventDeadlineAndDatepickers',
      'reports',
    ],
  },
  [SubscriptionType.PRO]: {
    price: 59,
    yearly: 599,
    features: [
      'allBasePlanFeaturesPlus',
      'upTo1000Members',
      'fiveAdmins',
      'discountOnPhysicalCards',
      'sendPushNotifications',
      'scheduleNotificationAndEmails',
      'personalizeEmailTemplates',
      'sellEventTickets',
    ],
  },
};
