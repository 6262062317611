import React from 'react';
import { HiOutlineKey } from 'react-icons/hi';
import { FiSettings } from 'react-icons/fi';
import {
  FaAt,
  FaBell,
  FaCalendar,
  FaCalendarCheck,
  FaEnvelope,
  FaEuroSign,
  FaQuestion,
  FaShareNodes,
  FaTicket,
} from 'react-icons/fa6';
import { FaFileAlt, FaThumbtack } from 'react-icons/fa';
import { HiOutlineChatBubbleLeftEllipsis, HiOutlineHome } from 'react-icons/hi2';
import { BsFillPeopleFill } from 'react-icons/bs';
import { BiSolidDownload } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { MdFileUpload } from 'react-icons/md';
import useBusinessTranslation from '../../../../../hooks/useBusinessTranslation';
import DropdownItem from './DropdownItem';
import Navigation from './Navigation';
import { isCommunity, isMoments } from '../../../../../constants';
import { RootState } from '../../../../../redux/reducers';
import { OnboardingStep } from '../../../../../types/misc';

export default function DropdownPanel(): JSX.Element {
  const { t } = useBusinessTranslation();
  const {
    connections,
    application: { onboardingStep },
  } = useSelector((state: RootState) => state);
  const connectionsWithPhoneNumber = connections.filter((c) => c.fields.PHONENUMBER);
  const connectionsWithEmail = connections.filter((c) => c.fields.EMAIL);
  const connectionsWithAddress = connections.filter((c) => c.fields.ADDRESS);

  const members = [
    {
      icon: <HiOutlineHome className="w-5 h-5" />,
      name: t('general.overview'),
      path: '/dashboard',
      id: 'overview',
    },
    {
      icon: <FaShareNodes className="w-5 h-5 flex-shrink-0" />,
      name: t('page.settings.shared.title'),
      path: '/settings/shared',
      condition: isMoments,
    },
    {
      icon: <FaQuestion className="w-5 h-5 flex-shrink-0" />,
      name: t('page.settings.requested.title'),
      path: '/settings/requested-data',
      id: 'requested-data',
    },
    {
      icon: <MdFileUpload className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.uploadExcel'),
      path: '/settings/upload-excel',
    },
    {
      icon: <BiSolidDownload className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.downloadExcel'),
      path: '/settings/export-excel',
    },
  ];

  const communication = [
    {
      icon: <FaBell className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.sendPushNotification'),
      path: '/communication/push-notification',
      state: { connections: connectionsWithPhoneNumber },
    },
    {
      icon: <FaAt className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.sendEmail'),
      path: '/communication/email',
      state: { connections: connectionsWithEmail },
    },
    {
      icon: <FaTicket className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.orderAddressLabels'),
      path: '/payment/address-label',
      state: { connections: connectionsWithAddress },
    },
    {
      icon: <FaEnvelope className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.sendCard'),
      path: '/payment/postcard',
      state: { connections: connectionsWithAddress },
    },
  ];

  const agenda = [
    {
      icon: <FaCalendar className="w-5 h-5 flex-shrink-0" />,
      name: t('page.event.regular.title'),
      path: '/events',
    },
    {
      icon: <FaThumbtack className="w-5 h-5 flex-shrink-0/>" />,
      name: t('page.event.date_picker.title'),
      path: '/events/date-picker',
    },
    {
      icon: <FaFileAlt className="w-5 h-5 flex-shrink-0" />,
      name: t('page.agenda.report.title'),
      path: '/events/report',
    },
  ];

  const general = [
    {
      icon: <FaEuroSign className="w-5  h-5 flex-shrink-0" />,
      name: t('page.settings.subscriptions.title'),
      condition: isCommunity,
      path: '/settings/subscriptions',
    },
    {
      icon: <HiOutlineKey className="w-5 h-5 flex-shrink-0" />,
      name: t('page.settings.admins.title'),
      path: '/settings/admins',
    },
    {
      icon: <FiSettings className="w-5  h-5 flex-shrink-0" />,
      name: t('page.settings.general.title'),
      path: '/settings',
    },
  ];

  return (
    <>
      <DropdownItem
        id="members"
        duration={onboardingStep !== undefined ? 100 : undefined}
        open={
          (onboardingStep &&
            [OnboardingStep.OVERVIEW, OnboardingStep.REQUESTED_DATA].includes(onboardingStep)) ||
          undefined
        }
        icon={BsFillPeopleFill}
        name={t('general.members')}>
        {members
          .filter((r) => r.condition === undefined || r.condition)
          .map((r) => (
            <Navigation {...r} key={r.path} open />
          ))}
      </DropdownItem>
      <DropdownItem
        id="communication"
        duration={onboardingStep !== undefined ? 100 : undefined}
        open={onboardingStep === OnboardingStep.COMMUNICATION || undefined}
        name={t('general.communication')}
        icon={HiOutlineChatBubbleLeftEllipsis}>
        {communication.map((r) => (
          <Navigation {...r} key={r.path} open />
        ))}
      </DropdownItem>
      {isCommunity && (
        <DropdownItem
          duration={onboardingStep !== undefined ? 100 : undefined}
          id="events"
          open={onboardingStep === OnboardingStep.EVENTS || undefined}
          name={t('general.agenda')}
          icon={FaCalendarCheck}>
          {agenda.map((r) => (
            <Navigation {...r} key={r.path} open />
          ))}
        </DropdownItem>
      )}
      <DropdownItem
        id="settings"
        duration={onboardingStep !== undefined ? 100 : undefined}
        open={onboardingStep === OnboardingStep.SETTINGS || undefined}
        name={t('general.settings')}
        icon={FiSettings}>
        {general
          .filter((r) => r.condition === undefined || r.condition)
          .map((r) => (
            <Navigation {...r} key={r.path} open />
          ))}
      </DropdownItem>
    </>
  );
}
