import React from 'react';
import {
  FaBaby,
  FaBriefcase,
  FaBuilding,
  FaBusinessTime,
  FaCalendarDay,
  FaCheck,
  FaExclamationTriangle,
  FaFacebookSquare,
  FaGlassCheers,
  FaInstagram,
  FaLinkedin,
  FaRing,
  FaTiktok,
  FaUniversity,
} from 'react-icons/fa';
import _ from 'lodash';
import {
  FaCheck as FaCheckThin,
  FaCakeCandles,
  FaCalendarCheck,
  FaChampagneGlasses,
  FaPeopleGroup,
  FaPersonRunning,
  FaXTwitter,
  FaQuestion,
} from 'react-icons/fa6';
import { AiOutlineClose } from 'react-icons/ai';
import { HiOutlineCalendar } from 'react-icons/hi';
import { FiPhone, FiMapPin, FiLink } from 'react-icons/fi';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { BsTextParagraph } from 'react-icons/bs';
import { MdOutlineQuiz } from 'react-icons/md';
import { BiRename } from 'react-icons/bi';
import { MomentType, ProfileDataType, SocialType } from '../types/Profile';
import { BusinessType } from '../types/business';
import { EventParticipationAnswer, EventType } from '../types/event';
import { CustomFieldType } from '../services/model/inviteService.model';

export const profileDataTypeIcons = {
  [ProfileDataType.ADDRESS]: (
    <FiMapPin className="h-6 w-5 icon " aria-hidden="true" strokeWidth="2" />
  ),
  [ProfileDataType.BIRTHDATE]: <FaCakeCandles className="h-5 w-5 icon" aria-hidden="true" />,
  [ProfileDataType.EMAIL]: (
    <HiOutlineEnvelope className="h-5 w-5  icon" strokeWidth="2" aria-hidden="true" />
  ),
  [ProfileDataType.PHONENUMBER]: <FiPhone className="h-5 w-5" strokeWidth="2" aria-hidden="true" />,
  [ProfileDataType.BUSINESSNAME]: (
    <FaBuilding className="h-5 w-5" strokeWidth="2" aria-hidden="true" />
  ),
  [ProfileDataType.MOMENT]: (
    <HiOutlineCalendar className="h-5 w-5 icon" strokeWidth="2" aria-hidden="true" />
  ),

  [ProfileDataType.SOCIAL]: <FiLink className="h-5 w-5 icon" strokeWidth="2" aria-hidden="true" />,
};

export const customFieldTypeIcons = {
  [CustomFieldType.TEXT]: <BsTextParagraph className="w-5 h-5 flex-shrink-0" />,
  [CustomFieldType.MULTIPLE_CHOICE]: <MdOutlineQuiz className="w-5 h-5 flex-shrink-0" />,
  [CustomFieldType.COMMUNICATION_NAME]: <BiRename className="w-5 h-5 flex-shrink-0" />,
};

export const businessTypeIcons = {
  [BusinessType.WEDDING]: FaRing,
  [BusinessType.BIRTH]: FaBaby,
  [BusinessType.FUNERAL]: FaCalendarDay,
  [BusinessType.OTHER_MOMENT]: FaCheck,
  [BusinessType.OTHER_COMMUNITY]: FaCheck,
  [BusinessType.SPORT]: FaPersonRunning,
  [BusinessType.STUDENT]: FaUniversity,
  [BusinessType.SOCIAL]: FaChampagneGlasses,
  [BusinessType.BUSINESS]: FaBusinessTime,
  [BusinessType.FAMILY]: FaPeopleGroup,
  [BusinessType.FRIENDS]: FaGlassCheers,
  [BusinessType.WORK]: FaBriefcase,
};

export const eventTypeIcons = {
  [EventType.SOCIAL]: FaPeopleGroup,
  [EventType.WORK]: FaBriefcase,
  [EventType.SPORT]: FaPersonRunning,
  [EventType.OTHER]: FaCalendarCheck,
};
export const momentTypeIcons = {
  [MomentType.CHILDBIRTH]: <FaCakeCandles className="h-5 w-5 icon" aria-hidden="true" />,
  [MomentType.WEDDING]: <FaGlassCheers className="h-5 w-5 icon" aria-hidden="true" />,
};

export const socialTypeIcons = {
  [SocialType.FACEBOOK]: <FaFacebookSquare className="h-5 w-5   icon" aria-hidden="true" />,
  [SocialType.LINKEDIN]: <FaLinkedin className="h-5 w-5 icon" aria-hidden="true" />,
  [SocialType.X]: <FaXTwitter className="h-5 w-5 icon" aria-hidden="true" />,
  [SocialType.TIKTOK]: <FaTiktok className="h-5 w-5 icon" aria-hidden="true" />,
  [SocialType.INSTAGRAM]: <FaInstagram className="h-5 w-5 icon" aria-hidden="true" />,
};
export const eventParticipantAnswerIcons = {
  [EventParticipationAnswer.YES]: FaCheckThin,
  [EventParticipationAnswer.MAYBE]: FaQuestion,
  [EventParticipationAnswer.NO]: AiOutlineClose,
  [EventParticipationAnswer.PENDING]: FaExclamationTriangle,
};

export const businessInvitePropertyTypeIcons = {
  ..._.omit(profileDataTypeIcons, ProfileDataType.MOMENT, ProfileDataType.SOCIAL),
  ...socialTypeIcons,
};
