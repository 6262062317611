import React from 'react';
import { twMerge } from 'tailwind-merge';

type CountUpCardProps = {
  variant?: 'primary' | 'secondary';
  justifyX?: 'center' | 'left' | 'right';
  justifyY?: 'center' | 'top' | 'bottom';
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const VARIANT_MAP = {
  primary: 'bg-primary-300 text-primary-900',
  secondary: 'bg-secondary text-primary-900',
}

const JUSTIFY_X_MAP = {
  'center': 'justify-center',
  'left': 'justify-start',
  'right': 'justify-end',
}

const JUSTIFY_Y_MAP = {
  'center': 'items-center',
  'top': 'items-start',
  'bottom': 'items-end',
}

export default function Card({ variant = 'secondary', justifyX = 'center', justifyY = 'center', className, ...props }: CountUpCardProps): JSX.Element {
  return (
    <div className={twMerge('flex flex-col p-4 rounded-md',
      VARIANT_MAP[variant],
      JUSTIFY_X_MAP[justifyX],
      JUSTIFY_Y_MAP[justifyY],
      className
    )} {...props} />
  );
}
