import React from 'react';
import classNames from 'classnames';
import { ProfileField } from '../../../../types/Profile';
import { socialTypeIcons } from '../../../../utils/iconUtils';
import { capitalizeFirstLetter, profileFieldToString } from '../../../../utils/stringUtils';

interface SocialProps {
  field: ProfileField;
  handleClick: (active: boolean, field: ProfileField) => void;
  active: boolean;
  mandatory?: boolean;
}
export default function Social({
  field,
  handleClick,
  active,
  mandatory,
}: SocialProps): JSX.Element {
  return (
    <div
      key={field.id!}
      onClick={() => handleClick(active, field)}
      className={classNames(
        'flex items-center border border-secondary-200 justify-between cursor-pointer w-full transition-all rounded-lg px-2 mr-6',
        {
          'bg-secondary-200': active,
        },
      )}>
      <div className="flex gap-2 items-start py-2 ">
        <div className="flex items-center gap-4">
          <div className="bg-secondary text-secondary-50 rounded-[6px] flex items-center justify-center p-1">
            {socialTypeIcons[field.social?.socialType as keyof typeof socialTypeIcons]}
          </div>
          <div>
            <p>
              {' '}
              {capitalizeFirstLetter(field.social?.socialType ?? '')} {mandatory && '*'}
            </p>
            <p>{field.social?.link}</p>
          </div>
        </div>
        <label
          className={classNames('text-sm text-normal text-black', {
            'text-black': active,
            'text-gray-700': !active,
          })}>
          {profileFieldToString(field)
            .split('\n')
            .map((x) => (
              <p>{x}</p>
            ))}
        </label>
      </div>
      <input
        type="checkbox"
        className="form-checkbox border-primary bg-secondary-50 h-5 w-5 text-primary-300 accent-primary-900 rounded-full focus:ring-0 focus:ring-transparent"
        checked={active}
      />
    </div>
  );
}
