import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaCalendar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { FaArrowRight, FaCircleCheck } from 'react-icons/fa6';
import _ from 'lodash';
import { PropsOf } from '@headlessui/react/dist/types';
import { twMerge } from 'tailwind-merge';
import useEvents from '../../../hooks/business/useEvents';
import { Event, EventViewType } from '../../../types/event';
import eventService from '../../../services/eventService';
import PageHeader from '../../components/misc/PageHeader';
import Button from '../../components/buttons/Button';
import SearchBar from '../../components/forms/SearchBar';
import { ParticipationAnswersOverview } from './EventDetailsPage';
import { formatDate, formatDateAndTime } from '../../../utils/stringUtils';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';
import { filterBySearch } from '../../../utils/filterUtils';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';

export default function EventsOverviewPage(): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [showPastEvents, setShowPastEvents] = useState<boolean>(false);
  const [fetchedPastEvents, setFetchedPastEvents] = useState<boolean>(false);
  const business = useSelectedBusiness();
  const events = useEvents(showPastEvents ? EventViewType.ALL_REGULAR : EventViewType.REGULAR);
  const isMd = useIsScreenSize('md');

  const filteredEvents = useMemo(() => {
    return filterBySearch(events, search, 'title', 'description');
  }, [events, search]);

  useEffect(() => {
    eventService.getUpcomingEvents().then(() => setFetchedPastEvents(false));
  }, [business]);

  useEffect(() => {
    if (showPastEvents && !fetchedPastEvents) {
      eventService.prependPastEvents().then(() => setFetchedPastEvents(true));
    }
  }, [showPastEvents, fetchedPastEvents]);

  return (
    <>
      <div className="flex flex-row gap-2 max-sm:flex-col pb-6">
        <div className="flex-1">
          <PageHeader
            title={t(`page.event.regular.title`)}
            subtitle={t(`page.event.regular.subtitle`)}
          />
        </div>
        <Link to="/events/create">
          <Button variant="primary">
            <FaCalendar className="h-5 w-5" />
            {t(`page.event.regular.add`)}
          </Button>
        </Link>
      </div>
      <div className="flex flex-1 flex-col gap-5 border-y py-4 border-primary-300">
        <div className="flex items-center justify-between gap-2 max-md:flex-col max-md:items-start">
          <SearchBar
            inputProps={{ placeholder: t('page.event.regular.searchEvents') }} className="max-w-[380px]"
            search={search} setSearch={setSearch}
          />
          <div className="max-md:self-end">
            <Button
              onClick={() => setShowPastEvents(prev => !prev)}
              className={`text-sm px-2 py-1 gap-1 border border-primary-300 ${showPastEvents ? 'hover:bg-opacity-100' : 'hover:bg-opacity-25 bg-opacity-0'}`}
              variant="secondary" type="button"
            >
              <FaCircleCheck /> {t('page.event.regular.showPastEvents')}
            </Button>
          </div>
        </div>
        {filteredEvents.length === 0 ? (
            <div className="text-primary-900 text-center">
              {showPastEvents
                ? t('page.event.regular.noEventsFound')
                : t('page.event.regular.noUpcomingEventsFound')}
            </div>
          )
          : isMd
            ? <EventList events={filteredEvents} />
            : <EventTable events={filteredEvents} />
        }
      </div>
    </>
  );
}

/* EventTable (large screens) */

interface EventTableProps {
  events: Event[];
}

function EventTable({ events }: EventTableProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <table className="w-full border-y border-primary-300 divide-y divide-primary-300 z-0">
      <thead>
      <tr>
        <HeaderCell>{t('page.event.regular.name')}</HeaderCell>
        <HeaderCell>{t('page.event.regular.startDate')}</HeaderCell>
        <HeaderCell>{t('page.event.regular.endDate')}</HeaderCell>
        <HeaderCell>{t('page.event.regular.participation')}</HeaderCell>
      </tr>
      </thead>
      <tbody>
      {events.map((event) => (
        <tr className="hover:bg-secondary-200 transition-all cursor-pointer group" key={event.id}
            onClick={() => navigate('/events/details', { state: { eventId: event.id } })}>
          <DataCell>{event.title}</DataCell>
          <DataCell>{formatDateAndTime(event.startTime)}</DataCell>
          <DataCell>{event.endTime ? formatDateAndTime(event.endTime) : '-'}</DataCell>
          <DataCell className="flex items-center justify-between">
            <ParticipationAnswersOverview answers={_.countBy(event.participants, 'answer')} />
            <div className="invisible group-hover:visible pr-4">
              <FaArrowRight className="flex-shrink-0 w-5 h-5" />
            </div>
          </DataCell>
        </tr>
      ))}
      </tbody>
    </table>
  );
}

function HeaderCell({ className, ...props }: PropsOf<'th'>): JSX.Element {
  return <th
    className={twMerge('py-3 pl-1 text-left text-sm font-medium text-primary-900 tracking-wider', className)} {...props} />;
}

function DataCell({ className, ...props }: PropsOf<'td'>): JSX.Element {
  return <td
    className={twMerge('pl-2 py-4 text-sm font-medium text-primary-900 whitespace-nowrap', className)} {...props} />;
}


/* EventList (small screens) */

interface EventListProps {
  events: Event[];
}

function EventList({ events }: EventListProps): JSX.Element {
  const navigate = useNavigate();
  const byDates = _.groupBy(events, (event) => formatDate(event.startTime));
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      {_.map(byDates, (eventsByDate, date) => (
        <>
          <div key={date} className="text-primary text-sm font-bold py-1 border-b border-primary-300">{date}</div>
          {eventsByDate.map((event) => (
            <div
              key={event.id}
              className="flex items-center justify-between p-4 bg-secondary-200 transition-all cursor-pointer rounded-lg shadow-sm"
              onClick={() => navigate('/events/details', { state: { eventId: event.id } })}
            >
              <div>
                <div className="text-sm font-semibold text-primary-900">{event.title}</div>
                <div className="text-xs">
                  <span className="font-medium">{t('page.event.regular.starts')}:</span>
                  {' '}{formatDateAndTime(event.startTime)}
                </div>
                {event.endTime && (
                  <div className="text-xs">
                    <span className="font-medium">{t('page.event.regular.ends')}:</span>
                    {' '}{formatDateAndTime(event.endTime)}
                  </div>
                )}
              </div>
              <div className="flex items-center gap-2">
                <ParticipationAnswersOverview size="sm" answers={_.countBy(event.participants, 'answer')} />
                {/*<FaArrowRight className="flex-shrink-0 w-4 h-4" />*/}
              </div>
            </div>
          ))}
        </>
      ))}
    </div>
  );
}
