import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../../../types/Profile';

interface BusinessNameInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  label?: boolean;
}

export default function BusinessNameInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  label = false,
}: BusinessNameInputProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {t('form.businessName.label')} {mandatory ? '*' : ''}
        </label>
      )}
      <div className="flex flex-row items-center rounded-lg w-full">
        <div className="flex flex-col w-full">
          <label
            htmlFor={field.id}
            className="pr-2 mb-1 text-sm focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3">
            {t('form.businessName.name')}
          </label>
          <input
            id={field.id}
            placeholder={t('form.businessName.name')}
            className="px-2  rounded-lg h-10 text-gray-600 w-full focus:ring-0 focus:ring-transparent focus:border-3"
            name="businessName"
            type="text"
            value={field.businessName ?? ''}
            onChange={(e) => {
              setField({ ...field, businessName: e.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
}
