/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import React, { HTMLProps, useEffect, useLayoutEffect, useState, useMemo } from 'react';
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  OnChangeFn,
  VisibilityState,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  FaChevronUp,
  FaChevronDown,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaRegPaperPlane,
} from 'react-icons/fa';
import { Connection, ConnectionType, setConnections } from '../../../redux/slices/connectionsSlice';
import { TagState } from '../../../types/Tag';
import tagService from '../../../services/tagService';
import ConnectionActions from '../dropdown/ConnectionActions';
import UserInfoModal from '../modals/UserInfoModal';
import ConnectionWithIcons from '../misc/ConnectionWithIcons';
import { store } from '../../../redux/store';
import TagDisplay from '../misc/TagDisplay';
import FilterOnTags from '../dropdown/FilterOnTags';
import { BasicInfo } from '../../../types/BasicInfo';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import i18n from '../../../locales/i18n';
import ApiImage from '../misc/ApiImage';
import { deleteConnections, getProfilePictureUrl } from '../../../utils/connectionUtils';
import IconBadge from '../icons/IconBadge';
import Button from '../buttons/Button';
import ConnectionStatus from '../misc/ConnectionStatus';
import Pagination from '../misc/Pagination';
import { VerifiedBadge } from '../icons/ConnectionTypeBadge';
import { SocialType } from '../../../types/Profile';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';

/**
 * @typedef {object} TableProps
 * @property {string[]} headers - The headers for the table.
 * @property {object[]} data - The data for the table.
 * @property {function} handleDelete - The function to handle the delete of a row.
 * @property {object[]} tags - The tags for the table.
 * @property {function} handleKeyPress - The function to handle the key press.
 * @property {function} handleCheckboxChange - The function to handle the checkbox change.
 */
interface TableProps {
  headers: string[];
  data: Connection[];
  tags: TagState[];
  filteredTags: string[];
  onTagClick: (tag: string) => void;
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    connection: Connection,
  ) => void;
  columnVisibility: VisibilityState;
  setColumnVisibility: OnChangeFn<VisibilityState>;
  selectAllClick: (bool: boolean) => void;
  selectedConnections: Connection[];
}

interface ChildTableProps extends TableProps {
  selectAll: boolean;
  setSelectAll: (arg0: boolean) => void;
  handleDelete: (arg0: string, connectionType: ConnectionType) => Promise<void>;
  openContacts?: Connection[];
  handleOpenContacts?: (arg0: any) => void;
  deleteTag: (arg0: string, arg1: string) => Promise<void>;
  pageIndex: number;
  pageSize: 10 | 20 | 30 | 40 | 50;
  setPageIndex: (arg0: number) => void;
  setPageSize: (arg0: 10 | 20 | 30 | 40 | 50) => void;
}

const columnHelper = createColumnHelper<Connection>();
/**
 * Handles the delete button click, deletes the connection with the given id.
 *
 * @param {string} id - The id of the connection to delete.
 * @returns {Promise<void>} - A promise that resolves when the connection is deleted.
 */
const handleDelete = async (id: string, type: ConnectionType): Promise<void> => {
  const promise = deleteConnections([{ id, type }]);
  await toast.promise(promise!, {
    pending: 'Deleting connection...',
    success: 'Connection deleted!',
    error: 'Failed to delete connection',
  });
  setConnections(store.getState().connections);
};

const createColums = (
  headers: string[],
  tags: TagState[],
  filteredTags: string[],
  onTagClick: (id: string) => void,
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    connection: Connection,
  ) => void,
  selectAllClick: (bool: boolean) => void,
) => {
  const columns = [];
  columns.push(
    columnHelper.display({
      id: 'select',
      header: ({ table }) => (
        <div className="px-1 ml-1">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              customOnChange: table.getToggleAllRowsSelectedHandler(),
              selectAllClick,
            }}
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              customOnChange: row.getToggleSelectedHandler(),
              handleCheckboxChange,
              connection: row.original,
            }}
          />
        </div>
      ),
      size: 60,
    }),
  );

  if (headers.find((x) => x === BasicInfo.FIRST_NAME)) {
    columns.push(
      columnHelper.accessor((x) => x, {
        id: 'name',
        header: i18n.t('general.name'),
        cell: (info) => {
          return (
            <div className="flex items-center gap-4">
              <ConnectionStatus connection={info.getValue()} interactive />
              <p className="overflow-ellipsis overflow-hidden whitespace-nowrap">{`${info.getValue()?.fields.FIRST_NAME} ${info.getValue()?.fields.LAST_NAME}`}</p>
            </div>
          );
        },
        sortingFn: (a, b) => {
          const nameA = `${a.original.fields.FIRST_NAME} ${a.original.fields.LAST_NAME}`;
          const nameB = `${b.original.fields.FIRST_NAME} ${b.original.fields.LAST_NAME}`;

          return nameA.localeCompare(nameB);
        }
      }),
    );
  }

  if (headers.find((x) => x === BasicInfo.COMMUNICATION_NAME)) {
    columns.push(
      columnHelper.accessor((x) => x.fields.COMMUNICATION_NAME, {
        id: 'communicationName',
        header: i18n.t('dataType.communicationName'),
        cell: (info) => info.getValue() || '-',
        sortingFn: 'text',
      }),
    );
  }

  if (headers.find((x) => x === BasicInfo.PHONENUMBER)) {
    columns.push(
      columnHelper.accessor((x) => x.fields.PHONENUMBER, {
        id: 'phoneNumber',
        header: i18n.t('dataType.phoneNumber'),
        cell: (info) => info.getValue() || '-',
        sortingFn: 'alphanumeric',
      }),
    );
  }

  if (headers.find((x) => x === BasicInfo.EMAIL)) {
    columns.push(
      columnHelper.accessor((x) => x.fields.EMAIL, {
        id: 'email',
        header: i18n.t('dataType.email'),
        cell: (info) => info.getValue() || '-',
        sortingFn: 'text',
      }),
    );
  }

  if (headers.find((x) => x === BasicInfo.ADDRESS)) {
    columns.push(
      columnHelper.accessor((x) => x.fields.ADDRESS, {
        id: 'address',
        header: i18n.t('dataType.address'),
        cell: (info) => <div className="whitespace-pre">{info.getValue()}</div> || '-',
        sortingFn: 'text',
      }),
    );
  }

  if (headers.find((x) => x === BasicInfo.BUSINESSNAME)) {
    columns.push(
      columnHelper.accessor((x) => x.fields.BUSINESSNAME, {
        id: 'businessName',
        header: i18n.t('dataType.businessName'),
        cell: (info) => info.getValue() || '-',
        sortingFn: 'datetime',
      }),
    );
  }

  if (headers.find((x) => x === BasicInfo.BIRTHDATE)) {
    columns.push(
      columnHelper.accessor((x) => x.fields.BIRTHDATE, {
        id: 'birthDate',
        header: i18n.t('dataType.birthDate'),
        cell: (info) => info.getValue() || '-',
        sortingFn: 'datetime',
      }),
    );
  }
  const socials = headers.filter((header) => header in SocialType);
  socials.forEach((social) => {
    columns.push(
      columnHelper.accessor((x) => x.fields[social], {
        id: social,
        header: () => capitalizeFirstLetter(social),
        cell: (info) => info.getValue() || '-',
        sortingFn: 'text',
      }),
    );
  });

  const customFields = headers.filter(
    (header) => !(header in BasicInfo) && !(header in SocialType) && header !== 'Tags',
  );
  customFields.forEach((c) => {
    columns.push(
      columnHelper.accessor((x) => x.fields[c], {
        id: c,
        header: c,
        cell: (info) => info.getValue() || '-',
        sortingFn: 'text',
      }),
    );
  });

  columns.push(
    columnHelper.display({
      id: 'tags',
      header: () => (
        <div className="flex">
          {i18n.t('general.tags')}
          {tags.length > 0 && (
            <FilterOnTags tags={tags} onTagClick={onTagClick} filterTags={filteredTags} />
          )}
        </div>
      ),
      cell: ({ row }) => <TagDisplay connection={row.original} withHover />,
      enableSorting: false,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <div className="w-8" />,
      cell: ({ row }) => (
        <div className="px-1">
          <ConnectionActions
            connectionId={row.original.id}
            connectionType={row.original.type}
            handleDelete={handleDelete}
          />
        </div>
      ),
    }),
  );

  return columns;
};

/**
 * A table component for the connections.
 *
 * @param {string[]} headers - The headers for the table.
 * @param {object[]} data - The data for the table.
 * @param {function} handleDelete - The function to handle the delete of a tag.
 * @param {object[]} tags - The tags for the table.
 * @param {function} handleKeyPress - The function to handle the key press for in the input field.
 * @param {function} handleCheckboxChange  - The function to handle the checkbox change.
 * @returns {JSX.Element} - The table component.
 */
export default function ConnectionsTable(props: TableProps): JSX.Element {
  const [selectAll, setSelectAll] = React.useState(false);
  const [openContacts, setOpenContacts] = React.useState<Connection[]>([]);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<10 | 20 | 30 | 40 | 50>(50);

  useEffect(() => {
    if (pageIndex === 0) return;
    if (pageSize * pageIndex >= props.data.length)
      setPageIndex(Math.floor(props.data.length / pageSize - 1));
  }, [props.data.length]);

  const deleteTag = async (connectionId: string, tagId: string): Promise<void> => {
    await tagService.removeTagFromConnection(connectionId, tagId);
  };
  const [isMobile, setMobile] = React.useState<boolean>(window.innerWidth < 1024);

  useEffect((): void => {
    window.addEventListener('resize', (): void => setMobile(window.innerWidth < 1024));
  }, []);

  useLayoutEffect(() => {
    setOpenContacts(
      openContacts
        .map((x) => props.data.find((y) => y.id === x.id && y.type === x.type))
        .filter((x) => x) as Connection[],
    );
  }, [props.data]);

  const handleOpenContacts = (connection: Connection): void => {
    if (openContacts.includes(connection)) {
      setOpenContacts(openContacts.filter((c): boolean => c !== connection));
    } else {
      setOpenContacts([...openContacts, connection]);
    }
  };

  // if (props.data.length === 0) return <div />; //todo empty state here

  if (isMobile)
    return (
      <SmallConnectionsTable
        {...props}
        selectAll={selectAll}
        handleDelete={handleDelete}
        setSelectAll={setSelectAll}
        openContacts={openContacts}
        handleOpenContacts={handleOpenContacts}
        deleteTag={deleteTag}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
      />
    );

  return (
    <BigConnectionsTable
      {...props}
      selectAll={selectAll}
      handleDelete={handleDelete}
      setSelectAll={setSelectAll}
      deleteTag={deleteTag}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPageIndex={setPageIndex}
      setPageSize={setPageSize}
    />
  );
}

function SmallConnectionsTable({
  data,
  selectAllClick,
  selectedConnections,
  handleCheckboxChange,
  selectAll,
  setSelectAll,
  openContacts,
  handleOpenContacts,
  pageIndex,
  pageSize,
  setPageSize,
  setPageIndex,
}: ChildTableProps): JSX.Element {
  const [profileImages, setProfileImages] = useState<Record<string, string>>({});

  const { t } = useTranslation();

  useEffect(() => {
    const fetchProfileImages = async (): Promise<void> => {
      if (!openContacts) return;
      const images: Record<string, string> = { ...profileImages };
      await Promise.all(
        openContacts.map(async (c): Promise<void> => {
          if (!profileImages[c.id]) {
            const profileImage = await getProfilePictureUrl(c).catch(() => '');
            images[c.id] = profileImage;
          }
        }),
      );
      setProfileImages(images);
    };

    fetchProfileImages();
  }, [openContacts]);

  return (
    <div
      data-testid="small-connections-table"
      className="rounded-lg border border-primary-300 mt-2 divide-y divide-primary-300">
      <div className="justify-between flex w-full p-4 rounded-t-lg">
        <input
          type="checkbox"
          data-testid="open-all"
          onChange={(): void => {
            selectAllClick(!selectAll);
            setSelectAll(!selectAll);
          }}
          checked={selectAll}
        />
        <div className="flex items-center">
          <span className="pl-1 inline-block">{`${
            selectedConnections.length === 0
              ? ''
              : `${selectedConnections.length} ${t('component.connectionsTable.selected')}`
          }`}</span>
        </div>
      </div>
      {data
        .filter((__, index) => index >= pageIndex * pageSize && index < (pageIndex + 1) * pageSize)
        .map(
          (row: Connection): JSX.Element => (
            <div
              className={`flex justify-start h-fit rounded-b-lg flex-col border-gray-300 w-full p-4 ${
                selectedConnections.includes(row) && 'bg-secondary-200'
              }`}>
              <div className="justify-between flex">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    data-testid={`${row.id}-checkbox`}
                    className="mr-2"
                    onChange={(event): void => {
                      if (selectAll) setSelectAll(false);
                      else if (
                        !selectedConnections.includes(row) &&
                        selectedConnections.length === data.length - 1
                      )
                        setSelectAll(true);
                      handleCheckboxChange(event, row);
                    }}
                    checked={selectedConnections.includes(row)}
                  />
                  <p className="text-sm font-semibold text-primary-900 whitespace-nowrap">
                    {`${row.fields.FIRST_NAME} ${row.fields.LAST_NAME}`}
                  </p>
                  {row.inviteSent && (
                    <div className="h-fit w-fit relative">
                      <IconBadge
                        icon={FaRegPaperPlane}
                        className="h-7 w-7 ml-2 peer"
                        iconClassName="h-4 w-4"
                      />
                      <div className="bg-primary-900 rounded-lg absolute opacity-0 peer-hover:opacity-100 duration-200 transition-all text-secondary-200 p-1 px-2 -bottom-6 -right-16 text-xs">
                        {i18n.t('general.inviteSent')}
                      </div>
                    </div>
                  )}
                  {row.type === ConnectionType.B2C_CONNECTION && (
                    <div className=" rounded-[6px] relative h-7 w-7 ml-2 peer p-1 bg-secondary-200 flex items-center justify-center">
                      <img src="/assets/vera_smile.svg" alt="connection" className="w-full peer" />
                      <div className="bg-primary-900 rounded-lg absolute opacity-0 peer-hover:opacity-100 duration-200 transition-all w-28 text-secondary-200 p-1 px-2 -bottom-6 -right-16 text-xs">
                        {`Vera ${i18n.t('contact.connection').toLowerCase()}`}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex font-bold items-center">
                  <ConnectionActions
                    connectionId={row.id}
                    connectionType={row.type}
                    handleDelete={handleDelete}
                  />
                  <button type="button" onClick={(): void => handleOpenContacts!(row)}>
                    {openContacts!.includes(row) ? (
                      <FaChevronUp
                        data-testid={`${row.id}-open-contact`}
                        className="h-6 w-6 font-bold mx-auto icon"
                      />
                    ) : (
                      <FaChevronDown
                        data-testid={`${row.id}-open-contact`}
                        className="h-6 w-6 font-bold mx-auto icon"
                      />
                    )}
                  </button>
                </div>
              </div>
              {openContacts!.includes(row) && (
                <div className="mt-2 items-center w-full justify-between flex relative">
                  <div className="w-full">
                    <div className="flex flex-col">
                      {profileImages[row.id] && (
                        <ApiImage
                          path={profileImages[row.id]}
                          alt="profile"
                          className="w-28 h-28 rounded-lg mr-8 mb-3 object-cover"
                        />
                      )}
                      <ConnectionWithIcons connection={row} />
                    </div>

                    <p className="text-xs font-normal text-gray-400 mt-4 mb-2">
                      {t('general.tags')}
                    </p>
                    <TagDisplay connection={row} />
                  </div>
                </div>
              )}
            </div>
          ),
        )}
      <Pagination
        className="pr-2"
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        length={data.length}
      />
    </div>
  );
}

const FIRST_COLUMN_CSS = 'sticky bg-secondary-50 z-[1] px-2 w-10 left-0';
const SECOND_COLUMN_CSS = 'sticky bg-secondary-50 z-[1] px-2 left-10';

function BigConnectionsTable({
  data,
  headers,
  selectAllClick,
  tags,
  handleCheckboxChange,
  onTagClick,
  filteredTags,
  pageIndex,
  pageSize,
  setPageSize,
  setPageIndex,
  columnVisibility,
  setColumnVisibility,
}: ChildTableProps): JSX.Element {
  const [modalConnection, setModalConnection] = useState<Connection>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = React.useState({});
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const tableData = useMemo(() => [...data], [data]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const business = useSelectedBusiness();

  useEffect(() => {
    setModalConnection(
      data.find((x) => x.id === modalConnection?.id && x.type === modalConnection?.type),
    );
  }, [data]);

  const table = useReactTable({
    data: tableData,
    columns: createColums(
      headers,
      tags,
      filteredTags,
      onTagClick,
      handleCheckboxChange,
      selectAllClick,
    ),
    state: {
      rowSelection,
      sorting,
      columnVisibility,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    enableRowSelection: true, //enable row selection for all rows
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    enableColumnResizing: true,
    debugTable: false,
    defaultColumn: {
      minSize: 0,
      maxSize: Number.MAX_SAFE_INTEGER,
    }
  });
  return (
    <div className="w-full overflow-x-auto relative">
      <table
        style={{
          width: table.getCenterTotalSize(),
        }}
        data-testid="connections-table"
        className="min-w-full border-y border-primary-300 divide-y divide-primary-300 z-0 h-full">
        <thead className="z-0">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="z-0" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                header.column.getIsVisible() ? (
                  <th
                    colSpan={header.colSpan}
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                    className={classNames(
                      'py-3 pl-1 text-left text-sm font-medium text-primary-900 tracking-wider relative', {
                        [FIRST_COLUMN_CSS]: header.id === 'select',
                        [SECOND_COLUMN_CSS]: header.id === 'name',
                      },
                    )}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={classNames('flex items-center', {
                          'cursor-pointer select-none': header.column.getCanSort(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.column.id === 'name' ? (
                          <div className="mr-4 ml-1">
                            <VerifiedBadge className="" />
                          </div>
                        ) : null}

                        {['select', 'actions', 'tags', 'name'].includes(header.column.id)
                          ? flexRender(header.column.columnDef.header, header.getContext())
                          : (
                            <p className="overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </p>
                          )}

                        {header.column.getCanSort() && !header.column.getIsSorted() ? (
                          <FaSort className="ml-2 mt-0.5" />
                        ) : null}
                        {{
                          asc: <FaSortUp className="ml-2 mt-0.5" />,
                          desc: <FaSortDown className="ml-2 mt-0.5" />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                    <div
                      className={classNames('absolute w-1 top-[15%] right-0 rounded-lg h-[70%] hover:opacity-100 cursor-col-resize touch-none select-none', {
                        'bg-primary opacity-100': header.column.getIsResizing(),
                        'bg-primary-300 opacity-10': !header.column.getIsResizing(),
                      })}
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                    />
                  </th>
                ) : null
              ))}
            </tr>
          ))}
        </thead>
        {table?.getRowModel().rows.length > 0 ? (
          <>
            <tbody className="z-0">
            {table?.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={`group cursor-pointer ${
                  row.getIsSelected() ? 'bg-secondary-200' : ''
                }`}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={classNames(
                      'bg-secondary-50 pl-2 max-w-0 py-4 text-sm font-medium text-primary-900 whitespace-nowrap flex-justify-center', {
                        'group-hover:bg-secondary-200': row.getIsSelected(),
                        [FIRST_COLUMN_CSS]: cell.column.id === 'select',
                        [SECOND_COLUMN_CSS]: cell.column.id === 'name',
                      },
                    )}
                    style={{
                      width: cell.column.id === 'select' ? undefined : cell.column.getSize()
                    }}
                    onClick={() => {
                      if (cell.column.id !== 'select' && cell.column.id !== 'actions' && cell.column.id !== 'tags') {
                          setModalConnection(row.original);
                          setModalOpen(true);
                        }
                    }}
                  >
                    {['select', 'actions', 'tags', 'name'].includes(cell.column.id)
                      ? flexRender(cell.column.columnDef.cell, cell.getContext())
                      : (
                        <p className={classNames({
                          'overflow-ellipsis overflow-hidden whitespace-nowrap': cell.column.id !== 'actions' && cell.column.id !== 'tags',
                        })}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </p>
                      )
                    }
                  </td>
                ))}
              </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
              <td colSpan={table?.getHeaderGroups()[0].headers.length}>
                <Pagination
                  className="justify-start"
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    length={data.length}
                  />
                </td>
              </tr>
            </tfoot>
          </>
        ) : (
          //empty state
          <tbody className="z-0">
            <tr>
              <td colSpan={table?.getHeaderGroups()[0].headers.length}>
                <div className="m-auto w-full text-center py-3">
                  {business?.inviteFields ? (
                    <div>{t('component.connectionsTable.noConnections')}</div>
                  ) : (
                    <>
                      <div className="mb-3">{t('component.connectionsTable.noLink')}</div>
                      <Button
                        variant="primary"
                        onClick={() =>
                          navigate('/invite-link', {
                            state: { view: 'SETUP' },
                          })
                        }
                        className="mx-auto">
                        <MdEdit data-testid="share-icon" className="h-5 w-5 " />
                        {t('component.connectionsTable.configureTable')}
                      </Button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>

      <UserInfoModal
        open={modalOpen}
        connection={modalConnection}
        setOpen={() => setModalOpen(false)}
      />
    </div>
  );
}

function IndeterminateCheckbox({
  indeterminate,
  customOnChange,
  handleCheckboxChange,
  selectAllClick,
  connection,
  className = '',
  ...rest
}: {
  indeterminate?: boolean;
  customOnChange: (event: unknown) => void;
  handleCheckboxChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    connection: Connection,
  ) => void;
  connection?: Connection;
  selectAllClick?: (bool: boolean) => void;
} & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);
  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);
  return (
    <input
      type="checkbox"
      ref={ref}
      className={`${className} ${!rest.checked ? 'group-hover:bg-secondary-200' : ''}`}
      onChange={(event): void => {
        customOnChange(event);
        if (handleCheckboxChange !== undefined) handleCheckboxChange(event, connection!);

        if (selectAllClick !== undefined) selectAllClick(!rest.checked!);
      }}
      {...rest}
    />
  );
}

const __test__ = {
  SmallConnectionsTable,
  BigConnectionsTable,
};
export { __test__ };
