import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import CountUpCard from '../../components/cards/CountUpCard';
import Card from '../../components/cards/Card';
import AnswerPercentageGraph from '../../components/graphs/AnswerPercentageGraph';
import AttendancePerPersonGraph from '../../components/graphs/AttendancePerPersonGraph';
import AttendancePerEventGraph from '../../components/graphs/AttendancePerEventGraph';
import DatePicker from '../../components/forms/DatePicker';
import useEvents from '../../../hooks/business/useEvents';
import { EventViewType } from '../../../types/event';
import eventService from '../../../services/eventService';
import PageHeader from '../../components/misc/PageHeader';
import Labeled from '../../components/misc/Labeled';
import PodiumGraph from '../../components/graphs/PodiumGraph';
import Button from '../../components/buttons/Button';
import { downloadAnalytics } from '../../../utils/fileUtils';
import { formatDateForFileName } from '../../../utils/stringUtils';

export default function EventReportsPage() {
  const { t } = useTranslation();
  const events = useEvents(EventViewType.ALL_REGULAR);

  const [startDate, setStartDate] = useState<Date | undefined>(
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 90), // today - 90 days
  );
  const [endDate, setEndDate] = useState<Date | undefined>(new Date(Date.now()));

  useEffect(() => {
    if (!startDate || !endDate) return;

    eventService.getEventAnalytics(startDate, endDate);
  }, [startDate, endDate]);

  const handleDownload = () => {
    if (!startDate || !endDate) return;

    downloadAnalytics(
      events,
      `${t('page.agenda.report.title')}_${t('general.from')}_${formatDateForFileName(startDate)}_${t('general.to')}_${formatDateForFileName(endDate)}`,
    );
  };

  return (
    <main className="flex flex-col gap-2">
      <PageHeader
        className="pb-6 mb-1 border-b border-primary-300"
        title={t('page.agenda.report.title')}
        subtitle={t('page.agenda.report.subtitle')}
      />
      <div className="flex gap-2 justify-between items-center flex-wrap">
        <div className="flex gap-4">
          <Labeled className="w-auto" label={t('page.agenda.report.startDate')}>
            <DatePicker date={startDate} setDate={setStartDate} includeTime={false} />
          </Labeled>
          <Labeled className="w-auto" label={t('page.agenda.report.endDate')}>
            <DatePicker date={endDate} setDate={setEndDate} includeTime={false} />
          </Labeled>
        </div>
        <Button className="ml-auto" onClick={handleDownload} variant="primary">{t('page.agenda.report.exportButton')}</Button>
      </div>

      {events.length === 0 ? (
        <div className="px-8 py-2 mt-4 bg-secondary rounded-lg self-center">
          <p className="flex gap-2 items-center">
            <FaInfoCircle />
            {t('page.agenda.report.noEvents')}
          </p>
        </div>
      ) : (
        <>
          <section className="flex gap-2 max-md:flex-col">
            <CountUpCard
              className="bg-secondary-200 flex-[1] min-h-[300px] aspect-square max-md:aspect-auto max-md:min-h-0"
              title={t(`page.agenda.report.event${events.length === 1 ? '' : 's'}`).toLowerCase()}
              count={events.length}
            />
            <Card className="bg-secondary-200 min-h-[200px] flex-[1]">
              <PodiumGraph events={events} />
              <h3 className="font-serif text-xl">{t('page.agenda.report.podiumTitle')}</h3>
            </Card>
            <Card className="bg-secondary-200 min-h-[200px] flex-[1]">
              <h3 className="font-serif text-xl">{t('page.agenda.report.answersTitle')}</h3>
              <AnswerPercentageGraph events={events} />
            </Card>
          </section>
          <section className="flex gap-2 min-h-[50vh]">
            <Card className="bg-secondary-200 w-full">
              <h3 className="font-serif text-xl">{t('page.agenda.report.answersPerEvent')}</h3>
              <AttendancePerEventGraph events={events} />
            </Card>
          </section>
          <section className="flex gap-2 min-h-[50vh]">
            <Card className="bg-secondary-200 w-full">
              <h3 className="font-serif text-xl">{t('page.agenda.report.answersPerUser')}</h3>
              <AttendancePerPersonGraph events={events} />
            </Card>
          </section>
        </>
      )}
    </main>
  );
}
