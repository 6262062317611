import React from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { COLOR_PRIMARY_300, COLOR_PRIMARY_900 } from '../../../constants';
import { Event, ZERO_PLACEHOLDER } from '../../../types/event';
import eventUtils from '../../../utils/eventUtils';
import AttendanceTooltip from './components/AttendanceTooltip';
import { RenderProfilePictureSvgOnAxis } from './components/ProfilePictureSvg';
import { decimalToPercentageString } from '../../../utils/stringUtils';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';

type AttendancePerPersonGraphProps = {
  events: Event[];
};

export default function AttendancePerPersonGraph({ events }: AttendancePerPersonGraphProps): JSX.Element {
  const isMd = useIsScreenSize('md');
  const profilePictures = eventUtils.eventsToProfilePictures(events);

  const attendance = eventUtils.transformToAttendanceEntriesPerUser(events);

  if (!attendance.length) return <></>;

  return (
    <ResponsiveContainer width={isMd ? '100%' : '50%'}>
      <BarChart
        data={attendance.map(([, value]) => ({
          ...value,
          yesFraction: value.yesFraction === 0 ? ZERO_PLACEHOLDER : value.yesFraction,
        }))}
        margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
      >
        <XAxis
          dataKey="name"
          /* eslint-disable-next-line react/no-unstable-nested-components */
          tick={(props) => <RenderProfilePictureSvgOnAxis {...props} profilePictures={profilePictures} />}
          interval={0}
          stroke={COLOR_PRIMARY_900}
        />
        <YAxis domain={[0, 1]} tickFormatter={decimalToPercentageString} stroke={COLOR_PRIMARY_900} />
        <Tooltip cursor={{ fill: '#859BFF2F' }} content={AttendanceTooltip} />
        <Bar dataKey="yesFraction" fill={COLOR_PRIMARY_300} />
      </BarChart>
    </ResponsiveContainer>
  );
}

