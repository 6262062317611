import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BusinessInvite } from '../../../services/model/inviteService.model';
import { createEmptyBusinessInvite } from '../../../utils/formatUtils';
import { LocalContactInfo } from '../../../services/model/localContactService.model';
import { ExcelColumn } from '../../../types/misc';
import { getSelectedBusiness } from '../../../hooks/business/useSelectedBusiness';
import useBusinessEffect from '../../../hooks/effects/useBusinessEffect';
import inviteService from '../../../services/inviteService';
import { isValidLocalContact } from '../../../utils/validationUtils';
import {
  ImportView,
  ImportViewHeader,
  RectifyContactView,
  RectifyContactViewHeader,
  SelectColumnsHeader,
  SelectColumnsView,
} from '../../components/multi-step/excel-import';
import { ChooseCountryHeader, ChooseCountryView } from '../../components/multi-step/excel-import/ChooseCountryView';

export default function UploadExcelPage(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [invite, setInvite] = useState<BusinessInvite>(createEmptyBusinessInvite());
  const [contactInfos, setContactInfos] = useState<LocalContactInfo[]>([]);
  const [columns, setColumns] = useState<ExcelColumn[]>();
  const [fileName, setFileName] = useState<string>('');
  const [isCountryMissing, setIsCountryMissing] = useState<boolean>(false);

  const business = getSelectedBusiness();

  const resetState = () => {
    setContactInfos([]);
    setColumns(undefined);
    setFileName('');
  }

  useBusinessEffect(() => {
    if (!business) return;
    inviteService.fetchInvite().then((res): void => setInvite(res));
  }, []);

  useEffect(() => {
    if (!columns?.length) setColumns(undefined);
  }, [columns]);

  const index = contactInfos.findIndex((c) => !isValidLocalContact(c, invite));
  const foundInvalidContact = index !== -1;
  const view = isCountryMissing ? 'missingCountry'
    : foundInvalidContact ? 'rectify'
      : columns ? 'selectColumns' : 'import';

  const handleOnSubmitFailed = () => {
    toast.warn(t('toast.warn.importLocalContacts.cancelled'));
    resetState();

  };

  const handleCountryMissing = () => {
    setIsCountryMissing(true);
  };


  return (
    <>
      <div className="grid grid-cols-8">
        <div className="col-span-8 lg:col-span-5 flex flex-col gap-2 border-b border-primary-300 pb-6 mb-6">
          {isCountryMissing ? (
            <ChooseCountryHeader />
          ) : foundInvalidContact ? (
            <RectifyContactViewHeader />
          ) : columns ? (
            <SelectColumnsHeader fileName={fileName} />
          ) : (
            <ImportViewHeader invite={invite} />
          )}
        </div>
      </div>
      <div className="grid grid-cols-8">
        <div className={`col-span-8 ${view === 'import' || view === 'missingCountry' ? 'lg:col-span-5' : ''}`}>
          {isCountryMissing ? (
            <ChooseCountryView
              invite={invite}
              contactsWithMissingCountry={contactInfos}
              setLocalContacts={setContactInfos}
              onSubmitSuccessful={() => {
                setIsCountryMissing(false);
                navigate('/dashboard');
              }}
              onMoreDataNeeded={() => setIsCountryMissing(false)}
              onSubmitFailed={handleOnSubmitFailed}
            />
          ) : foundInvalidContact ? (
            <RectifyContactView
              key={contactInfos[index].firstName + contactInfos[index].lastName}
              localContacts={contactInfos}
              setLocalContacts={setContactInfos}
              invite={invite}
              fileName={fileName}
              onSubmitSuccessful={() => navigate('/dashboard')}
            />
          ) : columns ? (
            <SelectColumnsView
              columns={columns}
              setColumns={setColumns}
              setLocalContacts={setContactInfos}
              invite={invite}
              onSubmitSuccessful={() => navigate('/dashboard')}
              onSubmitFailed={handleOnSubmitFailed}
              onMissingCountry={handleCountryMissing}
            />
          ) : (
            <ImportView setFileName={setFileName} setColumns={setColumns} />
          )}
        </div>
      </div>
    </>
  );
}
