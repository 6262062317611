import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventNotificationType, NotificationSettings } from '../../../types/event';
import Slider from '../misc/Slider';

interface EventNotificationFormProps {
  settings: NotificationSettings;
  setSettings: (settings: NotificationSettings) => void;
}

export default function EventNotificationForm({
  settings,
  setSettings,
}: EventNotificationFormProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col gap-4">
      {Object.keys(settings).map((participantType) => {
        const participantSettings = settings[participantType];
        return (
          <div className="flex flex-col gap-2">
            <h2 className="text-primary font-medium">
              {t(`form.notificationSettings.${participantType}.title`)}
            </h2>
            {Object.keys(participantSettings).map((notificationType) => {
              const notificationSetting =
                participantSettings[notificationType as EventNotificationType]!.setting;
              return (
                <div
                  className="flex cursor-pointer justify-between gap-2 items-center"
                  onClick={() =>
                    setSettings({
                      ...settings,
                      [participantType]: {
                        ...settings[participantType],
                        [notificationType]: { setting: !notificationSetting },
                      },
                    })
                  }>
                  <div className="flex flex-col">
                    <h3 className="font-medium">
                      {t(`form.notificationSettings.${participantType}.${notificationType}`)}
                    </h3>
                    <p>
                      {t(
                        `form.notificationSettings.${participantType}.${notificationType}_SUBTITLE`,
                      )}
                    </p>
                  </div>
                  <Slider state={notificationSetting} handleToggle={() => {}} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
