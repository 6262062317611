import React, { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

const classes = {
  primary: 'bg-primary text-secondary-50',
  'primary-outline': 'border border-primary text-primary-900',
  secondary: 'bg-primary-300 text-primary-900',
  tertiary: 'bg-secondary-200 text-primary-900',
};

type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'primary-outline';
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({
  variant,
  className = '',
  children,
  ...props
}: ButtonProps): JSX.Element {
  return (
    <button
      type="button"
      {...props}
      className={twMerge(
        'text-primary hover:text-opacity-80 rounded-[20px] disabled:opacity-20 transition-all whitespace-nowrap hover:bg-opacity-80 disabled:hover:bg-opacity-100 w-fit h-fit flex items-center font-[600] px-[16px] py-[8px] gap-2',
        variant ? classes[variant] : undefined,
        className,
      )}>
      {children}
    </button>
  );
}
