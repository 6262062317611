import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CgSpinner } from 'react-icons/cg';
import classNames from 'classnames';
import { FaCircleCheck } from 'react-icons/fa6';
import { Trans } from 'react-i18next';
import authService from '../../../services/authService';
import { ApplicationType } from '../../../redux/slices/applicationSlice';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import Button from '../../components/buttons/Button';

/* Login steps */
type LoginStep = 'EMAIL_FORM' | 'SENDING_EMAIL' | 'EMAIL_SENT' | 'EMAIL_ERROR';

/* Login page (parent component) */
export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();

  const [step, setStep] = useState<LoginStep>('EMAIL_FORM');
  const [email, setEmail] = useState<string>('');
  const { t } = useBusinessTranslation();

  const { isAuthenticated: auth } = useSelector((state: any): ApplicationType => state.application);

  useEffect((): void => {
    if (auth) navigate('/');
  }, [auth]);

  return (
    <div className="md:gap-10 md:flex-row md:justify-between flex-col gap-4 my-10 w-full flex flex-1 h-full max-w-7xl mx-auto min-h-[calc(100vh-9rem)]">
      <div className="md:w-[65%] md:gap-16 md:py-4 w-[90%] mx-auto gap-4 flex flex-col bg-secondary-200 justify-center items-center px-8 py-8 rounded-3xl">
        <h1 className="md:text-4xl text-3xl font-serif font-medium text-center">
          {t('page.login.welcomeMessage')}
        </h1>
        <img src={`${process.env.PUBLIC_URL}/assets/landing/dashboard.png`} alt="Dashboard" className="md:w-[70%] w-[50%]" />
        <a className="text-center" href="https://www.veraconnect.com/students" target="_blank" rel="noopener noreferrer">
          <Trans i18nKey="page.login.moreInformation">
            Do you want to know more about Vera Community? <span className="font-semibold underline">Click here</span>
          </Trans>
        </a>
      </div>
      <div
        className="w-full justify-center md:w-[43%] md:min-w-[420px] md:min-h-[600px] bg-secondary-50 h-fit rounded-3xl flex flex-col gap-4 pt-4">
        <div className="flex items-center gap-2 w-full px-4 md:p-0">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-serif font-medium">
              {step === 'EMAIL_SENT' ? t('page.login.emailSentTitle') : t('auth.login')}
            </h1>
            <p className="text-sm">
              {step === 'EMAIL_SENT' ? t('page.login.emailSentSubtitle') : t('page.login.description')}
            </p>
          </div>
        </div>
        <div className="h-full flex flex-col w-full px-4 md:px-0 rounded-b-3xl">
          <div className="flex flex-col -mt-2 gap-4 flex-1 h-full">
            {step === 'EMAIL_SENT'
              ? <EmailResend email={email} />
              : <LoginForm step={step} setStep={setStep} email={email} setEmail={setEmail} />}
          </div>
        </div>
      </div>
    </div>
  );
}

/* Login form (shown initially, while sending and on email error) */
type LoginFormProps = {
  step: LoginStep;
  setStep: Dispatch<SetStateAction<LoginStep>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
}

function LoginForm({ step, setStep, email, setEmail }: LoginFormProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const navigate = useNavigate();

  /*
   * Handles the login, doesn't allow empty fields.
   * @returns {void}
   */
  const handleLogin = async (): Promise<void> => {
    if (email === '') {
      toast.error(t('toast.error.email'));
      setStep('EMAIL_ERROR');
      return;
    }
    setStep('SENDING_EMAIL');
    // setTimeout(async () => {
    try {
      await authService.generateMagicLink(email);
      setStep('EMAIL_SENT');
    } catch (_error) {
      setStep('EMAIL_ERROR');
    }
    // }, 250);
  };

  return (
    <>
      <div>
        <label className="pr-2 mb-1 text-sm">{t('dataType.email')}</label>
        <input
          placeholder={t('page.login.emailPlaceholder')}
          name="email"
          type="email"
          disabled={step === 'SENDING_EMAIL' || step === 'EMAIL_SENT'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-5 flex-1 h-full justify-end items-end align-bottom min-h-10">
        <Button variant="primary" disabled={step === 'SENDING_EMAIL' || step === 'EMAIL_SENT'}
                onClick={handleLogin}>
          <CgSpinner className={classNames('animate-spin', {
            'hidden': step !== 'SENDING_EMAIL',
          })} />
          {t('page.login.button')}
        </Button>
        <div className="flex justify-end -mt-2">
          <span className="text-sm text-primary-900">
            {t('auth.noAccountYet')}{' '}
            <b
              className="border-b border-primary-900 cursor-pointer font-semibold "
              onClick={() => navigate('/onboarding')}>
              {t('page.login.make')}
            </b>
          </span>
        </div>
      </div>
    </>
  );
}

/* Email sent message (shown after email is sent successfully) */
type EmailSentMessageProps = {
  email: string;
}

function EmailResend({ email }: EmailSentMessageProps): JSX.Element {
  const { t } = useBusinessTranslation();

  const sendAgain = async (): Promise<void> => {
    authService.generateMagicLink(email)
      .then(() => toast.success(t('toast.success.emailSent')));
  };

  return (
    <>
      <div className="relative w-full">
        <input
          name="email"
          type="email"
          value={email}
          disabled
        />
        <FaCircleCheck
          className="absolute top-[50%] -translate-y-[50%] right-2 text-primary"
          size={24}
        />
      </div>
      <div className="flex flex-col justify-end items-end gap-2">
        <div className="text-sm text-primary-900 mr-1">
          {t('page.login.noEmailQuestion')}{' '}
          <button type="button" className="font-bold hover:opacity-80 cursor-pointer" onClick={sendAgain}>
            {t('page.login.sendAgain')}
          </button>
        </div>
      </div>
    </>
  );
}
