import React from 'react';
import { API_URL, COLOR_SECONDARY, COLOR_SECONDARY_50 } from '../../../../constants';
import { getInitialsFromAlias } from '../../../../utils/stringUtils';

interface ProfilePictureProps {
  picture: string | undefined;
  alias: string;
}

type RenderProfilePictureSvgOnPodiumProps =  {
  x?: string | number;
  y?: string | number;
  value?: string | number;
  name?: string;
  viewBox?: { x?: number; y?: number; width?: number; height?: number };
  profilePictures: { [key: string]: string };
};

export function RenderProfilePictureSvgOnPodium({ x, y, name, value, profilePictures, ...props }: RenderProfilePictureSvgOnPodiumProps): JSX.Element {
  const pictureUrl = value ? profilePictures[value] : undefined;

  return name ? (
    <g transform={`translate(${+(x || 0) + (props.viewBox?.width || 0) / 2},${+(props.viewBox?.y || 0) - 50})`}>
      <ProfilePictureSvg picture={pictureUrl} alias={name.split(' ').join('+')} />
    </g>
  ) : (
    <></>
  );
}

type RenderCustomAxisTicksProps = {
  x: number;
  y: number;
  payload: { value: string };
  profilePictures: { [key: string]: string };
};

export function RenderProfilePictureSvgOnAxis({ x, y, payload, profilePictures }: RenderCustomAxisTicksProps): JSX.Element {
  const pictureUrl = profilePictures[payload.value];

  return (
    <g transform={`translate(${x},${y})`}>
      <ProfilePictureSvg picture={pictureUrl} alias={payload.value.split(' ').join('+')} />
    </g>
  );
}

export function ProfilePictureSvg({ picture, alias }: ProfilePictureProps) {
  return picture ? (
    <ApiSvgImage
      path={picture}
    />
  ) : (
    <g>
      <rect x={-20} y={5} width="40" height="40" rx="12" fill={COLOR_SECONDARY} />
      <text x={0} y={10} dy={16} textAnchor="middle" fill={COLOR_SECONDARY_50} dominantBaseline="middle">
        {getInitialsFromAlias(alias)}
      </text>
    </g>
  )
    ;
}

interface Props {
  path: string;
  className?: string;
}

function ApiSvgImage({ path, className }: Props): JSX.Element {
  const uri =
    path?.startsWith('uploads') || path?.startsWith('./uploads')
      ? `${API_URL}/${path.replace('./', '').replace('\\', '/')}`
      : path;

  return <image x={-20} y={5} width={40} height={40} href={uri} className={className} clipPath="inset(0% round 12px)" />;
}
