import React, { useMemo } from 'react';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip } from 'recharts';
import { useTranslation } from 'react-i18next';
import { AttendantData, Event, EventParticipationAnswer } from '../../../types/event';
import eventUtils from '../../../utils/eventUtils';
import { COLOR_PRIMARY, COLOR_PRIMARY_300, COLOR_PRIMARY_900 } from '../../../constants';
import { RenderProfilePictureSvgOnPodium } from './components/ProfilePictureSvg';
import { decimalToPercentageString } from '../../../utils/stringUtils';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';

type PodiumGraphProps = {
  events: Event[];
};

type RankingPlace = 'first' | 'second' | 'third';

type PlaceData = {
  id: number;
  place: RankingPlace;
  color: string;
  value: number;
  name: string;
  userData: AttendantData;
}

type Ranking = PlaceData[];

export default function PodiumGraph({ events }: PodiumGraphProps): JSX.Element {
  const imageUrls = eventUtils.eventsToProfilePictures(events);
  const [hoveredCell, setHoveredCell] = React.useState<number | null>(null);

  const isMd = useIsScreenSize('md');

  const top3: Ranking = useMemo(() => {
    const [first, second, third] = eventUtils.transformToAttendanceToTop3(events);

    // The order represents a podium
    return [
      toPlaceData('second', second),
      toPlaceData('first', first),
      toPlaceData('third', third),
    ];
  }, [events]);

  return (
    <ResponsiveContainer aspect={isMd ? 2 : undefined}>
      <BarChart
        data={top3}
        margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
      >
        <Bar isAnimationActive={false} radius={6} dataKey="value" fill="#fff">
          {top3.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              onMouseEnter={() => setHoveredCell(index)}
              onMouseLeave={() => setHoveredCell(null)}
              fill={index === hoveredCell ? `${entry.color}80` : entry.color}
            />
          ))}

          <LabelList
            // @ts-ignore - The type definition is incorrect for the prop viewBox
            // eslint-disable-next-line react/no-unstable-nested-components
            content={(props) => <RenderProfilePictureSvgOnPodium profilePictures={imageUrls} {...props} />}
            className="font-serif" dataKey="name" position="top" fill={COLOR_PRIMARY_900}
          />
        </Bar>
        <Tooltip cursor={false} content={Top3Tooltip} />
      </BarChart>
    </ResponsiveContainer>
  );
}

type CustomTooltipProps = {
  active?: boolean;
  payload?: { payload?: PlaceData }[];
}

function Top3Tooltip({ active, payload }: CustomTooltipProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.agenda.report' });
  if (active && payload && payload.length && payload[0].payload && payload[0].payload.name) {
    const data = payload[0].payload;

    return (
      <div className="bg-white p-2 rounded-md">
        <p className="font-semibold">{placeToMedalIcon(data.place)} {data.name}</p>
        <hr />
        <p>{t('yesFraction')}: {decimalToPercentageString(data.userData.yesFraction)}</p>
      </div>
    );
  }

  return <></>;
}

function placeToMedalIcon(place: RankingPlace): string {
  switch (place) {
    case 'first':
      return '🥇';
    case 'second':
      return '🥈';
    case 'third':
      return '🥉';
    default:
      return '';
  }
}

const PLACE_TO_COLOR_MAP = {
  first: COLOR_PRIMARY_300,
  second: COLOR_PRIMARY,
  third: COLOR_PRIMARY_900,
};

const PLACE_TO_HEIGHT_MAP = {
  first: 10,
  second: 6,
  third: 4,
}

function toPlaceData(place: RankingPlace, payload: [string, AttendantData] | undefined): PlaceData {
  if (!payload) {
    return {
      id: -1,
      place,
      name: '',
      color: PLACE_TO_COLOR_MAP[place],
      value: PLACE_TO_HEIGHT_MAP[place],
      userData: {
        id: -1,
        name: '',
        yesFraction: 0,
        [EventParticipationAnswer.YES]: 0,
        [EventParticipationAnswer.NO]: 0,
        [EventParticipationAnswer.MAYBE]: 0,
        [EventParticipationAnswer.PENDING]: 0,
      },
    };
  }

  return {
    id: payload[1].id,
    place,
    name: payload[0],
    color: PLACE_TO_COLOR_MAP[place],
    value: PLACE_TO_HEIGHT_MAP[place],
    userData: payload[1],
  };
}
