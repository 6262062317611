import { useTranslation } from 'react-i18next';
import React from 'react';
import { AttendantData, EventParticipationAnswer, ZERO_PLACEHOLDER } from '../../../../types/event';
import { decimalToPercentageString } from '../../../../utils/stringUtils';

type CustomTooltipProps = {
  active?: boolean;
  payload?: { payload?: AttendantData }[];
}

export default function AttendanceTooltip({ active, payload }: CustomTooltipProps): JSX.Element {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 rounded-md">
        <p className="font-semibold">{payload[0].payload?.name}</p>
        <hr />
        {Object.values(EventParticipationAnswer).map((key) => (
          <p key={`${key}_${payload[0].payload?.id}`} className={key === EventParticipationAnswer.YES ? 'font-semibold text-primary-300' : 'text-sm'}>
            {t(`page.eventDetails.answerTitle.${key.toLowerCase()}`)}: {payload[0].payload?.[key as EventParticipationAnswer]}
            {key === EventParticipationAnswer.YES && payload[0].payload && ` (${decimalToPercentageString(payload[0].payload.yesFraction === ZERO_PLACEHOLDER ? 0 : payload[0].payload.yesFraction)})`}
          </p>
        ))}
      </div>
    );
  }

  return <></>;
}
